import React, { useState, useEffect, useRef } from 'react';
import { useAuth, getLoggedInUserData, getToken } from '../../context/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import useApi from '../../utility/apiCall';
import API_URL from '../../config/config';
import EmptyBox from '../../components/EmptyBox'; 
import FlashMessage from '../../components/FlashMessage'; 
import SelectDropItem from '../../components/CommonInput/SelectDropItem';
// import { Link } from 'react-router-dom';

// Icons Files
import Icons from '../../components/icons';
import empty3 from '../../img/icons/empty3.png';

// Demo Imges
import flag1 from "../../img/flag/flag(1).jpg";
import flag2 from "../../img/flag/flag(5).jpg";
import empty2 from '../../img/icons/empty2.png';
import foundClient from '../../img/icons/not-found-client.png';

// Design Files
// import CommonLayout from '../PageCommonLayout/CommonLayout';
import Breadcrumb from '../../components/Breadcrumb';
// import EmptyBox from '../../components/EmptyBox';
import FilterDropItem from '../../components/Filters/FilterDropItem';
import Input from '../../components/CommonInput/Input';
import DatePickerComponent from '../../components/CommonInput/DatePickerComponent';



export default function PageIBReportes() {
    const navigate = useNavigate();
    const { apiCall } = useApi();
    const { state, dispatch } = useAuth();
    const token = state.token || '';
    const isInitialMount = useRef(true);
    const [isLoading, setIsLoading ] = useState(null);
    const [isTradeLoading, setIsTradeLoading ] = useState(null);
    const [isDayTradeLoading, setIsDayTradeLoading] = useState(null);
    const [isClientTradeLoading, setIsClientTradeLoading] = useState(null);
    const [isAccountLoading, setIsAccountLoading] = useState(null);
    const [clientAccount, setClientAccount] = useState(null);
    const [clientTrade, setClientTrade] = useState(null);
    const [isSummaryTradeLoading, setIsSummaryTradeLoading] = useState(null);
    const [currentTab, setCurrentTab] = useState('clients');
    const [currentTabLabel, setCurrentTabLabel] = useState('My Client');
    
    const [clients, setClients] = useState([]);
    const [tradings, setTradings] = useState([]);
    const [dayTradings, setDayTradings] = useState([]);
    const [summaryTradings, setSummaryTradings] = useState([]);
    const [countries, setCountries] = useState([]);
    const [symbols, setSymbols] = useState([]);
    const [perPage,setPerPage] = useState(10);
    const [page,setPage] = useState(1);
    const [goPage, setGoPage ] = useState(null);
    const [totalPages,setTotalPages] = useState(0);
    const [sortBy, setSortBy] = useState('client_level');
    const [sortOrder, setSortOrder] = useState('asc');
    const [sortChange, setSortChange] = useState(null);
    const [requestSubmitError, setRequestSubmitError] = useState(false);
    const [requestSubmitErrorMsg, setRequestSubmitErrorMsg] = useState(false);
    const [keyword, setKeyword] = useState('');
    const [reset, setReset] =useState(false);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [country, setCountry ] = useState(null);
    const [symbol, setSymbol ] = useState(null);
    const [selectedCountry, setSelectedCountry]=  useState(null);
    const [selectedSymbol, setSelectedSymbol]=  useState(null);
    
    const [status, setStatus] = useState(null);
    const [assets, setAssets] = useState(null);
    const [year, setYear] = useState('Day');
    const [deposit, setDeposit] = useState(null);
    const [withdraw, setWithdraw] = useState(null);
    const [verificationLoader, setVerificationLoader] = useState(false);
    const [programOption, setProgramOption] = useState(false);
    const [selectedProgramId, setSelectedProgramId] = useState(null);
    const [selectedHeading, setSelectedHeading] = useState("");
    const [selectedOption, setSelectedOption] = useState("");
    const [programData, setProgramData] = useState([]);
        // ---
    const StatusOptions = ['All Statuses', 'Active', 'Inactive', 'Verified', 'Unverified', 'None deposit', 'Only has demo account'];
    const CountryOptions = ['All Countries', 'United States', 'Qatar', 'Yemen', 'Zimbabwe', 'Japan', 'Ireland', 'Finland', 'United Kingdom', 'India'];
    const WmqyOptions = ['Day','Week', 'Month', 'Quarter', 'Year'];
    const AssetsOptions = ['XAUUSD', 'EURUSD', 'GBPJPY', 'GBPUSD'];
    const [isLinkLoader, setIsLinkLoader] = useState(false);
    useEffect(() => {
    const fetchData = async () => {
        const loggedInUser = getLoggedInUserData();
        const isUserLoggedIn = !!loggedInUser;

        if (isUserLoggedIn) {
            if (loggedInUser.id && token) {
                try {

                    // Call fetchIbPrograms and wait for it to complete
                    await fetchIbPrograms();
                                        // Call getIbRequestStatus first
                    await getIbRequestStatus();
                    // Then call the other functions
                    await fetchCountriesData();
                    await fetchSymbolsData();
                } catch (error) {
                    console.error('Failed to fetch IB data:', error);
                }
            }
        }
    };

    if (isInitialMount.current) {
        isInitialMount.current = false;
        fetchData();
    }
}, [token]);


    const getIbRequestStatus = async () => {
        try {
          const response = await apiCall(API_URL +'get-ib-request-status', {
            user_id: state.userData.id,
            token: token,
          });
          if (response.data.success == '0') {
            // if(response.data.data.status === 'pending'){
            //     setRequestSubmitError(true);
            //     // setRequestSubmitErrorMsg("You don't have access to reports.");
            //     setTimeout(() => {
            //         navigate('/ib-dashboard');
            //     }, 2000);
            // }
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    
    };

    const fetchIbPrograms = async () => {
        try {
          setVerificationLoader(true);
          const response = await apiCall(API_URL + "get-ib-programs", {
            token: state?.token,
            user_id:state.userData.id
          });
      
          if (response.data.success == "0") {
            const options = response.data.data;  
            const names = options.map((item) => item.name);
            setProgramOption(names); // Set list of program names
            setProgramData(options);             
            if (options.length > 0) {
              setSelectedHeading(options[0].name);
              setSelectedProgramId(options[0].id); 
              setSelectedOption(options[0]);
            }else{
                setSelectedProgramId("0"); 
            }
          }
          setVerificationLoader(false);
        } catch (error) {
          console.error("An error occurred");
          setVerificationLoader(false);
        }
      };
      const handleSelectionChange = (selectedOption) => {
        const selectedOption1 = programData.find(
          (program) => program.name === selectedOption
        );
      
        if (selectedOption1) {
          setSelectedHeading(selectedOption1.name);
          setSelectedProgramId(selectedOption1.id);
          setSelectedOption(selectedOption1);
          
        } else {
          console.error("Program not found with the provided name");
        }
      };


    const fetchCountriesData = async () => {
        try {
          const response = await apiCall(API_URL +'get-countries', {
            nopaginate: "1",
          });
          if (response.data.success == '0') {
            setCountries(response.data.data);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    
    };
    const fetchSymbolsData = async () => {
        try {
          const response = await apiCall(API_URL +'get-symbols', {
            nopaginate: "1",
          });
          if (response.data.success == '0') {
            setSymbols(response.data.data);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    
    };

    
    const handleTabChange = async(tab,tabLabel) => {
        setCurrentTab(tab);
        setPage(1);
        handleReset(); 
        setCurrentTabLabel(tabLabel);
        // if(tab == 'clients'){
        //     getClients();
        // } else if(tab == 'trading'){
        //     getTrades();
        // } else if(tab == 'day'){
        //     getDaysTrades();
        // } else if(tab == 'summary'){
        //     getSummaryTrades();
        // } else if(tab == 'accounts'){
        //     getUserWallet();
        // } else if(tab == 'trades'){
        //     getClientTrades();
        // } else if(tab == 'deposit'){
        //     getDeposit();   
        // } else if(tab == 'withdraw'){
        //     getWithdraw();   
        // }
    }

    const getClients = async () => {
        try {
            setIsLoading(true);
            const response = await apiCall(API_URL +'get-ib-clients', {
                user_id: state.userData.id,
                token: token,
                page:page,
                per_page:10,
                sort_by:sortBy,
                sort_order:sortOrder,
                keyword:keyword,
                from_date:fromDate,
                to_date:toDate,
                country:country,
                status:status,
                assets:assets,
                program_id:selectedProgramId
            });
            if (response.data.success == '0') {
                setClients(response.data.data.data)
                setTotalPages(response.data.data.last_page)
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error('Error fetching data:', error);
        }
    };
    const getTrades = async () => {
        // Logic to get user wallet group-wise data
        try {
            setIsTradeLoading(true);
            const response = await apiCall(API_URL +'get-ib-trading-activities', {
                user_id: state.userData.id,
                token: token,
                page:page,
                per_page:10,
                sort_by:sortBy,
                sort_order:sortOrder,
                keyword:keyword,
                from_date:fromDate,
                to_date:toDate,
                country:country,
                status:status,
                symbol:symbol,
                year:year,
                account_type:'real',
                program_id:selectedProgramId
            });
            if (response.data.success == '0') {
                setTradings(response.data.data.data)
                setTotalPages(response.data.data.last_page)
            }
            setIsTradeLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const getDaysTrades = async () => {
        // Logic to get user wallet group-wise data
        try {
            setIsDayTradeLoading(true);
            const response = await apiCall(API_URL +'get-ib-daily-reports', {
                user_id: state.userData.id,
                token: token,
                page:page,
                per_page:10,
                sort_by:sortBy,
                sort_order:sortOrder,
                keyword:keyword,
                from_date:fromDate,
                to_date:toDate,
                country:country,
                status:status,
                symbol:symbol,
                year:year,
                program_id:selectedProgramId
            });
            if (response.data.success == '0') {
                setDayTradings(response.data.data.data)
                setTotalPages(response.data.data.last_page)
            }
            setIsDayTradeLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const getSummaryTrades = async () => {
        // Logic to get user wallet group-wise data
        try {
            setIsSummaryTradeLoading(true);
            const response = await apiCall(API_URL +'get-ib-summary-reports', {
                user_id: state.userData.id,
                token: token,
                page:page,
                per_page:10,
                sort_by:sortBy,
                sort_order:sortOrder,
                keyword:keyword,
                from_date:fromDate,
                to_date:toDate,
                country:country,
                status:status,
                symbol:symbol,
                year:year,
                program_id:selectedProgramId
            });
            if (response.data.success == '0') {
                setSummaryTradings(response.data.data.data)
                setTotalPages(response.data.data.last_page)
            }
            setIsSummaryTradeLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const getClientTrades = async () => {
        // Logic to get user wallet group-wise data
        try {
            setIsClientTradeLoading(true);
            const response = await apiCall(API_URL +'get-ib-client-trade', {
                user_id: state.userData.id,
                token: token,
                page:page,
                per_page:10,
                sort_by:sortBy,
                sort_order:sortOrder,
                from_date:fromDate,
                to_date:toDate,
                program_id:selectedProgramId
            });
            if (response.data.success == '0') {
                setClientTrade(response.data.data.data)
                setTotalPages(response.data.data.last_page)
            }
            setIsClientTradeLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const getUserWallet = async () => {
        // Logic to get user wallet group-wise data
        try {
            setIsAccountLoading(true);
            const response = await apiCall(API_URL +'get-ib-client-account', {
                user_id: state.userData.id,
                token: token,
                page:page,
                per_page:10,
                sort_by:sortBy,
                sort_order:sortOrder,
                from_date:fromDate,
                to_date:toDate,
                program_id:selectedProgramId
            });
            if (response.data.success == '0') {             
                setClientAccount(response.data.data.data)
                setTotalPages(response.data.data.last_page)
            }
            setIsAccountLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const getDeposit = async () => {
        try {
            setIsAccountLoading(true);
            const response = await apiCall(API_URL +'get-ib-client-deposit', {
                user_id: state.userData.id,
                token: token,
                page:page,
                per_page:10,
                sort_by:sortBy,
                sort_order:sortOrder,
                from_date:fromDate,
                to_date:toDate,
                program_id:selectedProgramId
            });
            if (response.data.success == '0') {                      
                setDeposit(response.data.data.data)
                setTotalPages(response.data.data.last_page)
            }
            setIsAccountLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    
    const getWithdraw = async () => {
        try {
            setIsAccountLoading(true);
            const response = await apiCall(API_URL +'get-ib-client-withdraw', {
                user_id: state.userData.id,
                token: token,
                page:page,
                per_page:10,
                sort_by:sortBy,
                sort_order:sortOrder,
                from_date:fromDate,
                to_date:toDate,
                program_id:selectedProgramId
            });
            if (response.data.success == '0') {                      
                setWithdraw(response.data.data.data)
                setTotalPages(response.data.data.last_page)
            }
            setIsAccountLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    
    const onChangePage = (page) => {
        if(parseInt(page) === 0){
            page = 1;
        }
        if(page < parseInt(totalPages)){
            setPage(page);
            setGoPage(page);
        } else {
            setPage(totalPages);
            setGoPage(totalPages);
        }
    }
    const changePrevPage = () => {
        if(page > 1){
          setPage(page-1);
        }
    }
    const changeNextPage = () => {
        if(page < totalPages){
            setPage(page+1);
        }
    }
    useEffect(() => {
        // console.log('');
        // console.log("selectedProgramId ",selectedProgramId);
        // console.log("page ",page);
        // console.log("sortChange ",sortChange);
        // console.log("reset ",reset);
        
        
        if(selectedProgramId && parseInt(page) > 0){
            if(currentTab == 'clients'){
                getClients();
            } else if(currentTab == 'trading'){
                getTrades();
            } else if(currentTab == 'day'){
                getDaysTrades();
            } else if(currentTab == 'summary'){
                getSummaryTrades();
            } else if(currentTab == 'accounts'){
                getUserWallet();
            } else if(currentTab == 'trades'){
                getClientTrades();
            } else if(currentTab == 'deposit'){
                getDeposit();
            } else if(currentTab == 'withdraw'){
                getWithdraw();
            }
        }
        // else if (selectedProgramId == "0") {
        //     getClients();
        // }
        if (selectedProgramId == "0" && parseInt(page) > 0) {
            if(currentTab == 'clients'){
                getClients();
            } else if(currentTab == 'trading'){
                getTrades();
            } else if(currentTab == 'day'){
                getDaysTrades();
            } else if(currentTab == 'summary'){
                getSummaryTrades();
            } else if(currentTab == 'accounts'){
                getUserWallet();
            } else if(currentTab == 'trades'){
                getClientTrades();
            } else if(currentTab == 'deposit'){
                getDeposit();
            } else if(currentTab == 'withdraw'){
                getWithdraw();
            }
        }
      }, [selectedProgramId,page,sortChange,reset,currentTab]);
    // useEffect(() => {
    //     if(parseInt(page) > 0){
    //         if(currentTab == 'clients'){
    //             getClients();
    //         } else if(currentTab == 'trading'){
    //             getTrades();
    //         } else if(currentTab == 'day'){
    //             getDaysTrades();
    //         } else if(currentTab == 'summary'){
    //             getSummaryTrades();
    //         } else if(currentTab == 'accounts'){
    //             getUserWallet();
    //         } else if(currentTab == 'trades'){
    //             getClientTrades();
    //         } else if(currentTab == 'deposit'){
    //             getDeposit();
    //         }
    //         else if(currentTab == 'withdraw'){
    //             getWithdraw();
    //         }
    //     }
    // }, [page]);

    const setSortByOrder = (sortByField) =>{
        let newSortOrder = sortOrder == 'asc' ? 'desc' : 'asc';
        setSortBy(sortByField);
        setSortOrder(newSortOrder);
        setSortChange(sortBy+','+sortOrder);
    }
    
    // useEffect(() => {
    //     if(sortChange !== null){
    //         if(currentTab == 'clients'){
    //             getClients();
    //         } else if(currentTab == 'trading'){
    //             getTrades();
    //         } else if(currentTab == 'day'){
    //             getDaysTrades();
    //         } else if(currentTab == 'summary'){
    //             getSummaryTrades();
    //         } else if(currentTab == 'accounts'){
    //             getUserWallet();
    //         } else if(currentTab == 'trades'){
    //             getClientTrades();
    //         } else if(currentTab == 'deposit'){
    //             getDeposit();
    //         } else if(currentTab == 'withdraw'){
    //             getWithdraw();
    //         }
    //     }
    // }, [sortChange]);

    const handleSearchChange = (keyword) =>{
        setKeyword(keyword);
    }

    const handleFromDateChange = (date) =>{
        setFromDate(date);
    }

    const handleToDateChange = (date) =>{
        setToDate(date);
    }
    
    const handleCountryChange = (country) => {
        setCountry(country)
    }

    const handleStatusChange= (status) => {
        setStatus(status);
    }

    const handleAssetsChange = (assets) => {
        setSymbol(assets);
    }

    const handleYearChange = (year) => {
        setYear(year)
    }

    useEffect(() => {
        if(country != null){
            setSelectedCountry(countries.find(countr => countr.id === country))
        }
    }, [country]);

    useEffect(() => {
        if(symbol != null){
            setSelectedSymbol(symbols.find(symb => symb.id === symbol))
        }
    }, [symbol]);

    const handleApply = () =>{
        setReset(false);
        setPage(1);
        if(currentTab == 'clients'){
            getClients();
        } else if(currentTab == 'trading'){
            getTrades();
        } else if(currentTab == 'day'){
            getDaysTrades();
        } else if(currentTab == 'summary'){
            getSummaryTrades();
        } else if(currentTab == 'accounts'){
            getUserWallet();
        } else if(currentTab == 'trades'){
            getClientTrades();
        } else if(currentTab == 'deposit'){
            getDeposit();
        } else if(currentTab == 'withdraw'){
            getWithdraw();
        }
    }

    const handleReset = () =>{
        setKeyword('');
        setFromDate(null);
        setToDate(null);
        setCountry(null);
        setSelectedCountry(null);
        setStatus(null);
        setAssets(null);
        setSymbol(null);
        setSelectedSymbol(null);
        setYear('Day')
        setReset(true);
        
    }

    // useEffect(() => {
    //     if(reset){
    //         if(currentTab == 'clients'){
    //             getClients();
    //         } else if(currentTab == 'trading'){
    //             getTrades();
    //         } else if(currentTab == 'day'){
    //             getDaysTrades();
    //         } else if(currentTab == 'summary'){
    //             getSummaryTrades();
    //         } else if(currentTab == 'accounts'){
    //             getUserWallet();
    //         } else if(currentTab == 'trades'){
    //             getClientTrades();
    //         } else if(currentTab == 'deposit'){
    //             getDeposit();
    //         } else if(currentTab == 'withdraw'){
    //             getWithdraw();
    //         }
    //     }
    // }, [reset]);


    const getSymbolImage = (symbolImages) => {
        // if (symbolImages.length == 2) {
        //     try {
        //       return (
        //         <div className="symbol-icons">
        //           <img src={symbolImages[0]} alt="" />
        //           <img src={symbolImages[1]} alt="" />
        //         </div>
        //       );
            
        //     } catch (error) {
        //       return ''; // Return an empty string if the image is not found
        //     }
          
        // } else if(symbolImages.length == 1){
        //   try {
        //     return (
        //       <div className="symbol-icons si-single">
        //         <img src={symbolImages[0]} alt="" />
        //       </div>
        //     );
        //     } catch (error) {
        //     return ''; // Return an empty string if the image is not found
        //     }
        // } else {
          return '';
        // }
      };

    
    
    
    return (
        <>
            <Breadcrumb className="mb-0" breadcrumbIcon="DocumentsSvg" breadcrumbHeading="Reports" />

            <div className="container-lg pt-3 px-lg-4 mb-5 mb-lg-0">
                <div className="row row-gap-4">

                    {/*  */}
                    <div className="col-xl-10 mx-auto">
                        <div className="common-box">
                            
                            <div className="common-box-heading mb-2">IB Reports</div>
                            <div className="common-box-sub-heading">Select a report type to view detailed revenue statistics. Filter the data for relevant insights or download it in CSv format for further analysis.</div>
                            <div className="row">
                            {!isLinkLoader && programOption.length > 0 && programOption && (
                                <div className="col-lg-4 col-sm-6">
                                    <FilterDropItem
                                        className="fdi-full-drop"
                                        label={selectedHeading}      // Use selectedHeading to show the selected name
                                        groupName="status"
                                        options={programOption}       // Provide the program names as options
                                        onChange={handleSelectionChange}  // This function will handle changes when a new option is selected
                                        selected={selectedHeading}    // The currently selected option (heading)
                                    />
                                </div>
                            )}
                            </div>
                            <div className="common-tabs-bx ctb-style-2 mt-4">
                                <div className={`ctb-item ${currentTab == 'clients'?'active':''}`} onClick={()=>handleTabChange('clients','My client')}>My Client</div>
                                <div className={`ctb-item ${currentTab == 'trading'?'active':''}`} onClick={()=>handleTabChange('trading','Trading activity')}>Trading activity</div>
                                {/* <div className={`ctb-item ${currentTab == 'day'?'active':''}`} onClick={()=>handleTabChange('day','Day')}>Day</div> */}
                                {/*<div className={`ctb-item ${currentTab == 'summary'?'active':''}`} onClick={()=>handleTabChange('summary','Summary')}>Summary</div>*/}
                                {/* <div className={`ctb-item ${currentTab == 'payments'?'active':''}`} onClick={()=>handleTabChange('payments','Payments')}>Payments</div> */}
                                <div className={`ctb-item ${currentTab == 'accounts'?'active':''}`} onClick={()=>handleTabChange('accounts','Accounts')}>Accounts</div>
                                <div className={`ctb-item ${currentTab == 'trades'?'active':''}`} onClick={()=>handleTabChange('trades','Trades')}>Trades</div>
                                <div className={`ctb-item ${currentTab == 'deposit'?'active':''}`} onClick={()=>handleTabChange('deposit','Deposit')}>Deposit</div>
                                <div className={`ctb-item ${currentTab == 'withdraw'?'active':''}`} onClick={()=>handleTabChange('withdraw','Withdraw')}>Withdrawals</div>
                            </div>

                        </div>
                    </div>
                    {/*  */}

                    {/*  */}
                    {requestSubmitError && <FlashMessage type="error" isvisible={true} message={requestSubmitErrorMsg} />}
                    <div className="col-xl-10 mx-auto">
                        <div className="common-box">
                            <div className="common-box-heading">{currentTabLabel}</div>

                            <div className="row signinup-group-style sg-fem-style my-4">
                                {currentTab == 'clients' &&
                                <div className="col-12">
                                    <Input className="sgl-icon" sgl={<Icons.SearchSvg/>} type="text" placeholder="Client name or account number" onChange={handleSearchChange} value={keyword}/>
                                </div>
                                }

                                <div className="col-lg-4 col-sm-6 col-6 mb-3">
                                    <DatePickerComponent label="From date" onChange={handleFromDateChange} value={fromDate}/>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-6 mb-3">
                                    <DatePickerComponent label="To date"  onChange={handleToDateChange} value={toDate}/>
                                </div>
                                {(currentTab == 'trading' || currentTab == 'summary') &&
                                <div className="col-lg-4 col-sm-6 col-6 mb-3">
                                    <FilterDropItem label="Year" groupName="Wmqy" options={WmqyOptions} selected={year} onChange={handleYearChange}/>
                                </div>
                                }
                                {currentTab !== 'day' && currentTab !== 'summary' && currentTab !== 'payments' && currentTab !== 'accounts' && currentTab !== 'trades' && currentTab !== 'deposit' && currentTab !== 'withdraw' && 
                                <div className="col-lg-4 col-sm-6 col-6 mb-3">
                                    {/* <FilterDropItem label="All Countries" groupName="Country" options={countries} onChange={onChangeDemo}/> */}
                                    <SelectDropItem
                                    className="mb-0"
                                    heading={selectedCountry ? selectedCountry.name  : "All Countries" }
                                    options={countries}
                                    onChange={handleCountryChange}
                                    setHeadingAsText={true}
                                    selected={selectedCountry}
                                />
                                </div>
                                }
                                
                                {currentTab == 'clients' &&
                                <div className="col-lg-4 col-sm-6 col-6 mb-3">
                                    <FilterDropItem label="All Statuses" groupName="Status" options={StatusOptions} selected={status} onChange={handleStatusChange}/>
                                </div>
                                }
                                {currentTab == 'trading' &&
                                <div className="col-lg-4 col-sm-6 col-6 mb-3">
                                    {/* <FilterDropItem label="All Assets" groupName="Assets" options={AssetsOptions} selected={assets} onChange={handleAssetsChange}/> */}
                                    <SelectDropItem
                                    className="mb-0"
                                    heading={selectedSymbol ? selectedSymbol.name  : "All Assets" }
                                    options={symbols}
                                    onChange={handleAssetsChange}
                                    setHeadingAsText={true}
                                    selected={selectedSymbol}
                                    />
                                </div>
                                }
                                
                                <div className="col-12">
                                    <div className="d-flex flex-wrap justify-content-end gap-2">
                                        <div className="common-btn-item cbi-fill" onClick={handleApply}><span>Apply</span></div>
                                        <div className="common-btn-item cbi-outline" onClick={handleReset}><span>Reset</span></div>
                                    </div>
                                </div>
                                
                            </div>

                            <div className="table-responsive">
                            {((currentTab == 'clients' && clients && clients.length > 0 && !isLoading) || (currentTab == 'clients' && isLoading )) && 
                                <table className="custom-table ct-hover">
                                    <thead>
                                        <tr>
                                            <th className={sortBy === 'client_level' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('client_level')}>Level <div className="th-sort-icons" ><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                            <th className={sortBy === 'name' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('name')}>Client <div className="th-sort-icons" ><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                            <th className={sortBy === 'email' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('email')}>Email <div className="th-sort-icons" ><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                            <th>Phone Number</th>
                                            <th className={sortBy === 'date' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('date')}>Register date <div className="th-sort-icons"><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                            <th className={sortBy === 'ib_profit' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('ib_profit')}>IB reward <div className="th-sort-icons"><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                            <th className={sortBy === 'traded_volume' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('traded_volume')}>Volume <div className="th-sort-icons"><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                            <th className={sortBy === 'is_ib' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('is_ib')}>Is IB <div className="th-sort-icons"><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                            <th className={sortBy === 'total_balance' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('total_balance')}>Balance/Equity <div className="th-sort-icons"><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                            <th className={sortBy === 'last_order_date' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('last_order_date')}>Last order <div className="th-sort-icons"><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div> </th>
                                        </tr>
                                    </thead>
                                    { !isLoading && <tbody>
                                    {clients && clients.length > 0 && !isLoading &&
                                        clients.map((client, index) => (
                                        <tr key={index}>
                                            <td>
                                                {client.client_level}
                                            </td>
                                            <td>
                                                {client.name}
                                                <div className="country-td">
                                                    <img src={client.country_flag} />
                                                    {client.country}
                                                </div>
                                            </td>
                                            <td>{client.email}</td>
                                            <td>{client.phone_number}</td>
                                            <td>{client.date_formatted}</td>
                                            <td>${client.commission_rate.toFixed(4)}</td>
                                            <td>{client.traded_volume.toFixed(4)} lots</td>
                                            <td>{client.is_ib ? "Yes" : "No"}</td>
                                            <td>${client.total_balance} / ${client.total_equity}</td>
                                            <td>{client.last_order_formmated_date}</td>
                                            <Link className="ct-tr-full-link" to={`/ib-myclient/${client.client_id}`}></Link>
                                        </tr>
                                        ))
                                    }
                                        
                                    </tbody>
                                    }

                                </table>
                            }


                            {((currentTab == 'trading' && tradings && tradings.length > 0 && !isTradeLoading) || (currentTab == 'trading' && isTradeLoading )) && 
                                <table className="custom-table">
                                    <thead>
                                        <tr>
                                            <th className={sortBy === 'date' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('date')}>Date <div className="th-sort-icons" ><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                            <th className={sortBy === 'country' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('country')}>Country<div className="th-sort-icons"><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                            {/* <th className={sortBy === 'account_type' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('account_type')}>Account Type <div className="th-sort-icons"><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th> */}
                                            <th className={sortBy === 'symbol' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('symbol')}>Asset <div className="th-sort-icons"><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                            <th className={sortBy === 'traded_volume' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('traded_volume')}>Traded Volume <div className="th-sort-icons"><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                            <th className={sortBy === 'total_clients' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('total_clients')}>Clients <div className="th-sort-icons"><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                        </tr>
                                    </thead>
                                    { !isTradeLoading && <tbody>
                                    {tradings && tradings.length > 0 && !isTradeLoading &&
                                        tradings.map((trade, index) => (
                                        <tr key={index}>
                                            <td>
                                                {trade.date}
                                            </td>
                                            <td>
                                                <img src={trade.country_flag} />
                                                &nbsp;{trade.country}
                                            </td>
                                            {/* <td>{trade.account_type}</td> */}
                                            <td><div className="symbol-bx">
                                                {getSymbolImage(trade.symbol.symbol_images)}
                                                {trade.symbol.name}
                                                </div></td>
                                            <td>{trade.traded_volume.toFixed(4)} lots</td>
                                            <td>{trade.total_clients}</td>
                                        </tr>
                                        ))
                                    }
                                        
                                    </tbody>
                                    }
                                </table>
                            }

                            {((currentTab == 'day' && dayTradings && dayTradings.length > 0 && !isDayTradeLoading) || (currentTab == 'day' && isDayTradeLoading )) && 
                                <table className="custom-table">
                                        <thead>
                                            <tr>
                                                <th className={sortBy === 'date' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('date')}>Date <div className="th-sort-icons" ><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                                <th className={sortBy === 'profit' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('profit')}>IB reward<div className="th-sort-icons"><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                                <th className={sortBy === 'traded_volume' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('traded_volume')}>Traded Volume <div className="th-sort-icons"><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                                <th className={sortBy === 'commission_rate' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('commission_rate')}>USD per Lot <div className="th-sort-icons"><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                                <th className={sortBy === 'active_clients' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('active_clients')}>Active Clients <div className="th-sort-icons"><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                            </tr>
                                        </thead>
                                        { !isDayTradeLoading && <tbody>
                                        {dayTradings && dayTradings.length > 0 && !isDayTradeLoading &&
                                            dayTradings.map((trade, index) => (
                                            <tr key={index}>
                                                <td>{trade.date_formattad}</td>
                                                <td>{trade.profit.toFixed(2)}</td>
                                                <td>{trade.lots_traded} lots</td>
                                                <td>${trade.commission_rate.toFixed(2)} / lot </td>
                                                <td>{trade.active_clients}</td>
                                            </tr>
                                            ))
                                        }
                                            
                                        </tbody>
                                        }
                                </table>
                            }

                            {((currentTab == 'summary' && summaryTradings && summaryTradings.length > 0 && !isSummaryTradeLoading) || (currentTab == 'summary' && isSummaryTradeLoading )) &&
                                <table className="custom-table">
                                    <thead>
                                        <tr>
                                            <th className={sortBy === 'date' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('date')}>Date <div className="th-sort-icons" ><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                            <th className={sortBy === 'profit' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('profit')}>IB reward<div className="th-sort-icons"><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                            <th className={sortBy === 'total_deposits' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('total_deposits')}>Deposit<div className="th-sort-icons"><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                            <th className={sortBy === 'traded_volume' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('traded_volume')}>Traded Volume <div className="th-sort-icons"><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                            
                                        </tr>
                                    </thead>
                                    { !isSummaryTradeLoading && <tbody>
                                    {summaryTradings && summaryTradings.length > 0 && !isSummaryTradeLoading &&
                                        summaryTradings.map((trade, index) => (
                                        <tr key={index}>
                                            <td>{trade.date_formattad}</td>
                                            <td>${trade.commission_rate.toFixed(4)}</td>
                                            <td>${trade.total_deposits}</td>
                                            <td>{trade.lots_traded} lots</td>
                                        </tr>
                                        ))
                                    }
                                        
                                    </tbody>
                                    }
                                </table>
                            }
                            {((currentTab == 'accounts' && clientAccount && clientAccount.length > 0 && !isLoading) || (currentTab == 'accounts' && isLoading )) && 
                                <table className="custom-table ct-hover">
                                    <thead>
                                        <tr>
                                            <th className={sortBy === 'account_number' ? 'active ' + sortOrder : ''} onClick={() => setSortByOrder('account_number')}>Account Number<div className="th-sort-icons"><Icons.CaretUpSvg /><Icons.CaretDownSvg /></div></th>
                                            <th className={sortBy === 'deposit' ? 'active ' + sortOrder : ''} onClick={() => setSortByOrder('deposit')}>Deposits<div className="th-sort-icons"><Icons.CaretUpSvg /><Icons.CaretDownSvg /></div></th>
                                            <th className={sortBy === 'withdraw' ? 'active ' + sortOrder : ''} onClick={() => setSortByOrder('withdraw')}>Withdrawals<div className="th-sort-icons"><Icons.CaretUpSvg /><Icons.CaretDownSvg /></div></th>
                                            <th className={sortBy === 'balance' ? 'active ' + sortOrder : ''} onClick={() => setSortByOrder('balance')}>Balance<div className="th-sort-icons"><Icons.CaretUpSvg /><Icons.CaretDownSvg /></div></th>
                                            <th className={sortBy === 'equity' ? 'active ' + sortOrder : ''} onClick={() => setSortByOrder('equity')}>Equity<div className="th-sort-icons"><Icons.CaretUpSvg /><Icons.CaretDownSvg /></div></th>
                                            <th className={sortBy === 'profit' ? 'active ' + sortOrder : ''} onClick={() => setSortByOrder('profit')}>Profit<div className="th-sort-icons"><Icons.CaretUpSvg /><Icons.CaretDownSvg /></div></th>
                                            <th className={sortBy === 'trades' ? 'active ' + sortOrder : ''} onClick={() => setSortByOrder('trades')}>Trades<div className="th-sort-icons"><Icons.CaretUpSvg /><Icons.CaretDownSvg /></div></th>
                                            <th className={sortBy === 'lots' ? 'active ' + sortOrder : ''} onClick={() => setSortByOrder('lots')}>Vol.lots<div className="th-sort-icons"><Icons.CaretUpSvg /><Icons.CaretDownSvg /></div></th>
                                            <th className={sortBy === 'created_at' ? 'active ' + sortOrder : ''} onClick={() => setSortByOrder('created_at')}>Created<div className="th-sort-icons"><Icons.CaretUpSvg /><Icons.CaretDownSvg /></div></th>
                                        </tr>
                                    </thead>
                                    {!isAccountLoading && (
                                        <tbody>
                                            {clientAccount && clientAccount.length > 0 && clientAccount.map((account, index) => (
                                                <tr key={index}>
                                                    <td>{account.account_number}</td>
                                                    <td>${account.deposit}</td>
                                                    <td>${account.withdraw ? account.withdraw : 0}</td>
                                                    <td>{account.balance}</td>
                                                    <td>{account.equity}</td>
                                                    <td>{account.profit.toFixed(2)}</td>
                                                    <td>{account.trade}</td>
                                                    <td>{account.lots.toFixed(2)}</td>
                                                    <td>{account.created_at_formattad}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    )}
                                </table>
                            }
                            {((currentTab == 'trades' && clientTrade && clientTrade.length > 0 && !isLoading) || (currentTab == 'trades' && isClientTradeLoading )) && 
                                <table className="custom-table ct-hover">
                                    <thead>
                                        <tr>
                                            <th className={sortBy === 'email' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('email')}>Email<div className="th-sort-icons" ><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                            <th className={sortBy === 'user_id' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('user_id')}>Client Id<div className="th-sort-icons" ><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                            <th className={sortBy === 'account' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('account')}>Platform<div className="th-sort-icons" ><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                            <th className={sortBy === 'account_number' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('account_number')}>Account Id<div className="th-sort-icons" ><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                            <th className={sortBy === 'symbol_name' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('symbol_name')}>Symbol<div className="th-sort-icons" ><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                            <th className={sortBy === 'trade_id' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('trade_id')}>Trade Id<div className="th-sort-icons" ><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                            <th className={sortBy === 'side' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('side')}>Side<div className="th-sort-icons" ><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                            <th className={sortBy === 'position' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('position')}>Position<div className="th-sort-icons" ><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                            <th className={sortBy === 'lots' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('lots')}>Volume<div className="th-sort-icons" ><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                            <th className={sortBy === 'trade_execution_date' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('trade_execution_date')}>Trade Execution Time<div className="th-sort-icons"><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div> </th>
                                            <th className={sortBy === 'price' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('price')}>Price<div className="th-sort-icons"><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                            <th className={sortBy === 'profit' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('profit')}>Profit<div className="th-sort-icons"><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                            <th className={sortBy === 'reward' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('reward')}>Reward<div className="th-sort-icons"><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div> </th>
                                            </tr>
                                    </thead>
                                    { !isClientTradeLoading && <tbody>
                                    {clientTrade && clientTrade.length > 0 && !isClientTradeLoading &&
                                        clientTrade.map((trade, index) => (
                                        <tr key={index}>
                                            <td>{trade.user ? trade.user.email : "-"}</td>
                                            <td>{trade.user ? trade.user.sequence :"-"}</td>
                                            <td>{trade.account ? trade.account : "-"}</td>
                                            <td>{trade.account_number ? trade.account_number : "-"}</td>
                                            <td><div className="symbol-bx">
                                                {getSymbolImage(trade.symbol.symbol_images)}
                                                {trade.symbol_name ? trade.symbol_name : "-"}
                                                </div></td>
                                            <td>{trade.trade_id ? trade.trade_id : '-'}</td>
                                            <td className={`${
                                                trade.side === "buy" ? "td-green" : "td-red"
                                                }`}>{trade.side ? trade.side : '-'}
                                            </td>
                                            <td>{trade.position ? trade.position : '-'}</td>
                                            <td>{trade.lots ? trade.lots : '-'}</td>
                                            <td>{trade.open_date ?trade.open_date_formattad : "-"}</td>
                                            <td>${trade.price ? trade.price.toFixed(5) : 0}</td>
                                            <td className={`${ (trade.position != 'close' && trade.loss == undefined) ? '' : trade.profit > 0 ? "td-green" : "td-red"}`}>
                                                {(trade.position != 'close' && trade.loss == undefined) ? '-'
                                                : trade.profit > 0
                                                ? trade.profit.toFixed(4)
                                                : "- " + trade.loss.toFixed(4)}
                                            </td>
                                            <td>{trade.reward ? trade.reward.toFixed(4) : 0}</td>
                                        </tr>
                                        ))
                                    }           
                                    </tbody>
                                    }

                                </table>
                            }
                            {((currentTab == 'deposit' && deposit && deposit.length > 0 && !isAccountLoading) || (currentTab == 'deposit' && isAccountLoading )) && 
                                <table className="custom-table ct-hover">
                                    <thead>
                                        <tr>
                                            <th className={sortBy === 'email' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('email')}>Client Email<div className="th-sort-icons" ><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                            <th className={sortBy === 'account_number' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('account_number')}>Account<div className="th-sort-icons" ><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                            <th className={sortBy === 'payment_method_name' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('payment_method_name')}>Payment method<div className="th-sort-icons" ><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                            <th className={sortBy === 'amount' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('amount')}>Amount <div className="th-sort-icons"><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div> </th>
                                            <th className={sortBy === 'created_at' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('created_at')}>Date<div className="th-sort-icons"><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                            </tr>
                                    </thead>
                                    { !isAccountLoading && <tbody>
                                    {deposit && deposit.length > 0 && !isAccountLoading &&
                                        deposit.map((deposit, index) => (
                                        <tr key={index}>
                                            <td>{deposit.user ? deposit.user.email : "-"}</td>
                                            <td>{deposit.user_wallet ? deposit.user_wallet.account_number : "-"}</td>
                                            <td>{deposit.payment_method_name}</td>
                                            <td>$ {deposit.amount}</td>
                                            <td>{deposit.created_at_formattad}</td>
                                        </tr>
                                        ))
                                    }           
                                    </tbody>
                                    }

                                </table>
                            }
                            {((currentTab == 'withdraw' && withdraw && withdraw.length > 0 && !isAccountLoading) || (currentTab == 'withdraw' && isAccountLoading )) && 
                                <table className="custom-table ct-hover">
                                    <thead>
                                        <tr>
                                            <th className={sortBy === 'email' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('email')}>Client Email<div className="th-sort-icons" ><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                            <th className={sortBy === 'account_number' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('account_number')}>Account<div className="th-sort-icons" ><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                            <th className={sortBy === 'payment_method_name' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('payment_method_name')}>Payment method<div className="th-sort-icons" ><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                            <th className={sortBy === 'amount' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('amount')}>Amount <div className="th-sort-icons"><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div> </th>
                                            <th className={sortBy === 'created_at' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('created_at')}>Date<div className="th-sort-icons"><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                            </tr>
                                    </thead>
                                    { !isAccountLoading && <tbody>
                                    {withdraw && withdraw.length > 0 && !isAccountLoading &&
                                        withdraw.map((withdraw, index) => (
                                        <tr key={index}>
                                            <td>{withdraw.user ? withdraw.user.email : "-"}</td>
                                            <td>{withdraw.user_wallet ? withdraw.user_wallet.account_number : "-"}</td>
                                            <td>{withdraw.payment_method_name}</td>
                                            <td>$ {withdraw.amount}</td>
                                            <td>{withdraw.created_at_formattad}</td>
                                        </tr>
                                        ))
                                    }           
                                    </tbody>
                                    }

                                </table>
                            }
                            
                            {currentTab == 'clients' && clients && clients.length == 0 && !isLoading &&
                                <EmptyBox 
                                className="empty-div-bx no-invert"
                                img={foundClient}
                                eh="Clients not found" 
                                esh="Try adjusting filters to get results."
                            /> 
                            }
                            
                            {currentTab == 'trading' && tradings && tradings.length == 0 && !isTradeLoading &&
                                <EmptyBox 
                                className="empty-div-bx no-invert"
                                img={empty2}
                                eh="Trades not found" 
                                esh="Try adjusting filters to get results."
                            /> 
                            }
                            {currentTab == 'day' && dayTradings && dayTradings.length == 0 && !isDayTradeLoading &&
                                <EmptyBox 
                                className="empty-div-bx no-invert"
                                img={empty2}
                                eh="Trades not found" 
                                esh="Try adjusting filters to get results."
                            /> 
                            }
                            
                            {currentTab == 'summary' && summaryTradings && summaryTradings.length == 0 && !isSummaryTradeLoading &&
                                <EmptyBox 
                                className="empty-div-bx no-invert"
                                img={empty2}
                                eh="Trades not found" 
                                esh="Try adjusting filters to get results."
                            /> 
                            }

                            {(currentTab == 'payments') &&
                                <EmptyBox 
                                className="empty-div-bx no-invert"
                                img={foundClient}
                                eh="Clients not found" 
                                esh="Try adjusting filters to get results."
                            /> 
                            }
                            {currentTab == 'accounts' && clientAccount && clientAccount.length == 0 && !isClientTradeLoading &&
                                <EmptyBox 
                                className="empty-div-bx no-invert"
                                img={empty2}
                                eh="Accounts Not Found" 
                                esh="Try adjusting filters to get results."
                            /> 
                            }
                            {currentTab == 'trades' && clientTrade && clientTrade.length == 0 && !isClientTradeLoading &&
                                <EmptyBox 
                                className="empty-div-bx no-invert"
                                img={empty2} 
                                eh="Trades not Found" 
                                esh="Try adjusting filters to get results."
                            /> 
                            }
                            {currentTab == 'deposit' && deposit && deposit.length == 0 && !isAccountLoading &&
                                <EmptyBox 
                                className="empty-div-bx" 
                                eh="No Deposits Found" 
                                esh="Try adjusting filters to get results."
                            /> 
                            }
                            {currentTab == 'withdraw' && withdraw && withdraw.length == 0 && !isAccountLoading &&
                                <EmptyBox 
                                className="empty-div-bx no-invert" 
                                img={empty3}
                                eh="No Withdrawals Found" 
                                esh="Try adjusting filters to get results."
                            /> 
                            }
                            </div>

                            {(isLoading || isTradeLoading || isSummaryTradeLoading || isDayTradeLoading || isAccountLoading || isClientTradeLoading) &&
                                <div className="row mx-0 row-gap-2">
                                    <div className="col-12 px-0">
                                        <div className="skeletant-bx skeletant-design sh-47"></div>
                                    </div>
                            
                                    <div className="col-12 px-0">
                                        <div className="skeletant-bx skeletant-design sh-47"></div>
                                    </div>
                            
                                    <div className="col-12 px-0">
                                        <div className="skeletant-bx skeletant-design sh-47"></div>
                                    </div>
                            
                                    <div className="col-12 px-0">
                                        <div className="skeletant-bx skeletant-design sh-47"></div>
                                    </div>
                                </div>
                            }

                            {((currentTab == 'clients' && clients && clients.length > 0 && !isLoading) || (currentTab == 'trading' && tradings && tradings.length > 0 && !isTradeLoading) || (currentTab == 'day' && dayTradings && dayTradings.length > 0 && !isDayTradeLoading) || (currentTab == 'summary' && summaryTradings && summaryTradings.length > 0 && !isSummaryTradeLoading) || (currentTab == 'accounts' && clientAccount && clientAccount.length > 0 && !isAccountLoading) || (currentTab == 'trades' && clientTrade && clientTrade.length > 0 && !isClientTradeLoading) || (currentTab == 'deposit' && deposit && deposit.length > 0 && !isAccountLoading) || (currentTab == 'withdraw' && withdraw && withdraw.length > 0 && !isAccountLoading)) &&
                            <div className="filter-bx ib-bottom-filter mt-4">

                                <div className="filter-left d-flex justify-content-end align-items-center column-gap-2">
                                    <div className="signinup-group-style d-flex column-gap-2 align-items-center justify-content-center">
                                        <div className="sgr-outer-label mb-0">Go to</div>
                                        <Input className="gopageinput mb-0" type="number" placeholder="Page" value={goPage ? goPage : ''} onChange={onChangePage} />
                                    </div>
                                </div>
                                <div className="filter-right">
                                    <div className="pagination-bx">
                                        <div className="pagination-btn pb-left" onClick={changePrevPage}>Prev</div>
                                        <div className="pagination-text"><div>{page}</div><span>/</span><div>{totalPages}</div></div>
                                        <div className="pagination-btn pb-right" onClick={changeNextPage}>Next</div>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                    {/*  */}

                </div>
            </div>
        </>
    )
}
