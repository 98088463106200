import React, { useState, useEffect, useRef } from 'react';
import { useAuth, getLoggedInUserData, getToken } from '../../context/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import useApi from '../../utility/apiCall';
import API_URL from '../../config/config';
import { Modal } from 'react-bootstrap';
import FlashMessage from '../../components/FlashMessage'; 
// // Images Common File
import * as Img from '../../components/Img';
// import BannerDemo from "../../img/banner/dashboard.jpeg"

// Icons Files
import Icons from '../../components/icons';

// winprofx5setup exe file
import tradeSteup from "../../img/winprofx5setup.exe";
import empty4 from "../../img/icons/empty4.png"

// Design Files
import Breadcrumb from '../../components/Breadcrumb';
import BannerImg from "../../components/BannerImg";
import EmptyBox from '../../components/EmptyBox';
import MT5AccountCard from './MT5AccountCard';
import FilterDropItem from '../../components/Filters/FilterDropItem';
import SelectDropItem from '../../components/CommonInput/SelectDropItem';
import Input from '../../components/CommonInput/Input';
import RadioInput from '../../components/CommonInput/RadioInput';


export default function Wallets() {
  const { apiCall } = useApi();
  const navigate = useNavigate();
  const { state, dispatch } = useAuth();
  const token = state.token || '';
  const [mt5Accounts, setMt5Accounts] = useState(null);
  const isInitialMount = useRef(true);
  const [mt5TabType, setMt5TabType] = useState('real');
  const [sortType, setSortType] = useState('Default');
  const [sortOrder, setSortOrder] = useState('asc');
  const [mt5TabTypeDefault, setMt5TabTypeDefault] = useState(true);
  const [sortTypeDefault, setSortTypeDefault] = useState(true);
  const [sortOrderDefault, setSortOrderDefault] = useState(true);
  const [isLoading, setIsLoading ] = useState(null);
  const [isRenBtnLoading, setIsRenBtnLoading ] = useState(null);
  const [isLevBtnLoading,setIsLevBtnLoading] = useState(null);
  const [isChangePwdRandomLoading,setIsChangePwdRandomLoading] =  useState(null);
  const [isOtpVerifyLoading, setIsOtpVerifyLoading] =  useState(null);
  const [isChangePwdLoading, setIsChangePwdLoading] =  useState(null);
  
  const [isBtnLoading, setIsBtnLoading ] = useState(false);
  const [products, setProducts] = useState(null);
  const [accountTypes, setAccountTypes] = useState(null);
  const [leverages, setLeverages] = useState(null);
  const [startAmounts, setStartAmounts] = useState(null);
  const [optionDisabled,setOptionDisabled] = useState('cdi-disabled');
  const [errors, setErrors] = useState({});
  const [renameErrors,setRenameErrors] = useState({});
  const [leverageErrors,setLeverageErrors] = useState({});
  const [otpVerificationErrors,setOtpVerificationErrors] = useState({});
  const [changePwdErrors,setChangePwdErrors] = useState({});
  const [requestSubmitSuccess, setRequestSubmitSuccsess] = useState(false);
  const [requestSubmitSuccessMsg,setRequestSubmitSuccsessMsg] = useState(null);
  const [requestSubmitError, setRequestSubmitError] = useState(false);
  const [requestSubmitErrorMsg, setRequestSubmitErrorMsg] = useState(null);
  //account create fields
  const [mt5Type, setMt5Type]= useState('demo');
  const [leverage, setLeverage] = useState(null);
  //const [currencyId, setCurrencyId] = useState(null);
  const [startAmount, setStartAmount] = useState(null);
  const [accountTypeId, setAccountTypeId] = useState(null);
  const [productId,setProductId] = useState(null);
  const [accountId,setAccountId] = useState(null);
  const [currentAccount,setCurrentAccount] = useState(null);
  const [currentAccountId,setCurrentAccountId] = useState(null);
  const [bannerImg, setBannerImg] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  //rename account fields
  const [name, setName] = useState(null);

  //change password fields
  const [passwordType, setPasswordType] = useState(null);
  const [passwordMethod,setPasswordMethod] = useState(null);

  //change Leverage
  const [updatedLeverageId, setUpdatedLeverageId] = useState(null);
  
  //otp verification
  const [otpVerify, setOtpVerify] = useState(null);
  
  //change password
  const [newPassword, setNewPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [changeOption, setChangeOption] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedProductName, setSelectedProductName] = useState(null);
  const [filters, setFilters] = useState({});
  const [verificationLabel,setVerificationLabel] = useState('Level 0');
  const [verificationPer,setVerificationPer] = useState(0);
  const [verificationLoader,setVerificationLoader] = useState(false);
  const [fetched,setFetched] = useState(false);
  const [currencyInrRate, setCurrencyInrRate] = useState(1);
  const [caption, setCaption] = useState(null);

  const handleStatusChange = (accountId, status) => {
      setFilters(prevFilters => ({
          ...prevFilters,
          [accountId]: status,
      }));
  };

  const copyClick = (value) => {
    setRequestSubmitSuccsess(false);
    setRequestSubmitError(false);
    navigator.clipboard.writeText(value)
      .then(() => {
        setRequestSubmitSuccsess(true);
        setRequestSubmitSuccsessMsg('Text copied to clipboard');
        // console.log('Text copied to clipboard');
      })
      .catch((error) => {
        setRequestSubmitError(true)
        setRequestSubmitErrorMsg('Error copying text')
        // console.error('Error copying text: ', error);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      const loggedInUser = getLoggedInUserData();
      const isUserLoggedIn = !!loggedInUser;
      
      if (isUserLoggedIn) {
        if (loggedInUser.id && token) {
          try {
            const verifiation = await fetchVerification();
            const transactions = await getUserMt5Accounts();
            const products = await getProducts();
            //const accountTypes = await getAccountTypes();
           // const leverages = await getLeverages()
           // const startAmounts = await getStartAmounts()
            // Assuming getUserWalletGroupWise returns the data needed for wallets and walletGroups
          } catch (error) {
            console.error('Failed to fetch wallet group data:', error);
          }
        }
      }
    };

    if (isInitialMount.current) {
      isInitialMount.current = false;
      fetchData();
    }
  }, [token]);

  const getUserMt5Accounts = async () => {
    // Logic to get user wallet group-wise data
    try {
      setIsLoading(true);
      const response = await apiCall(API_URL +'get-mt5-accounts', {
          user_id: state.userData.id,
          token: token,
          nopaginate:1,
          mt5_type:mt5TabType,
          sort:sortType,
          sort_order:sortOrder
          //transfer_type:transferTypeFilter,
         // transaction_status:statusFilter
      });
      if (response.data.success == '0') {
        console.log('{data.leverage.value}',response.data.data);
        
        setMt5Accounts(response.data.data);
      }
      setIsLoading(false);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
  };
  const getCurrencyRates = async () => {
    try {
      const response = await apiCall(API_URL + "latest-currency-rate", {
        user_id: state.userData.id,
        token: token,
      });
      // console.log(response.data);
      if (response.data.success == "0") {
        setCurrencyInrRate(response.data.data.inr);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const isCurrencyRatesFetched = useRef(false);
  useEffect(() => {
    if (!isCurrencyRatesFetched.current) {
      getCurrencyRates();
      isCurrencyRatesFetched.current = true;
    }
  }, []);
  const fetchVerification = async () => {
    try {
      setVerificationLoader(true);
      setFetched(false);
        const response = await apiCall(API_URL + 'get-user-verification-level', {
          user_id: state?.userData?.id,
          token: state?.token,
        });
        if (response.data.success == '0') {
          if (response.data.data.index === '1') {
            setCaption(response.data.data.index)
          }
        }
    } catch (error) {
        console.error('An error occurred');
        setVerificationLoader(false);
    }
  }
  // const getUserMt5AccountsExport = async () => {
  //   try {
  //     setIsLoading(true);
  
  //     const response = await apiCall(API_URL + 'get-mt5-accounts-export', {
  //       user_id: state.userData.id,
  //       token: token,
  //       nopaginate: 1,
  //       mt5_type: mt5TabType,
  //       sort: sortType,
  //       sort_order: sortOrder
  //     }, {
  //       responseType: 'blob' // Ensure the response is treated as a Blob
  //     });
  
  //     if (response) {
  //       const url = window.URL.createObjectURL(new Blob([response.data]));
  //       const link = document.createElement('a');
  //       link.href = url;
  //       link.setAttribute('download', 'user_wallets_export.csv'); // or use the filename from response headers
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //     }
  
  //     setIsLoading(false);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //     setIsLoading(false);
  //   }
  // };

  const getProducts = async () => {
    // Logic to get user wallet group-wise data
    try {
      const response = await apiCall(API_URL +'get-products', {
          user_id: state.userData.id,
          token: token,
          nopaginate:1,
          type:mt5Type
          //transfer_type:transferTypeFilter,
         // transaction_status:statusFilter
      });
      if (response.data.success == '0') {
        setProducts(response.data.data);
      }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
  };

  const getAccountTypes = async () => {
    // Logic to get user wallet group-wise data
    try {
      const response = await apiCall(API_URL +'get-account-types', {
          user_id: state.userData.id,
          token: token,
          nopaginate:1,
          //transfer_type:transferTypeFilter,
         // transaction_status:statusFilter
      });
      if (response.data.success == '0') {
        // setAccountTypes(response.data.data);
        if (mt5Type === 'demo') {
          const filteredAccountTypes = response.data.data.filter(account => account.name == 'Standard');
          setAccountTypes(filteredAccountTypes);
      } else {
          setAccountTypes(response.data.data);
      }
      }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
  };

  const getLeverages = async () => {
    // Logic to get user wallet group-wise data
    try {
      const response = await apiCall(API_URL +'get-leverages', {
          user_id: state.userData.id,
          token: token,
          nopaginate:1,
          //transfer_type:transferTypeFilter,
         // transaction_status:statusFilter
      });
      if (response.data.success == '0') {
        setLeverages(response.data.data);
      }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
  };

  const fetchBanner = async () => {
    try {
        const response = await apiCall(`${API_URL}get-banners`, {});


        if (response.data.success) {
            // setBannerImg(response.data.data.image_url);
            setBannerImg({
              imageUrl: response.data.data.image_url,
              link: response.data.data.link
            });
        } else {
            console.error("API Response error:", response.data.message || 'Unknown error');
        }
    } catch (error) {
        console.error("Error fetching banner:", error.message || error);
    } finally {
        setLoading(false);
    }
    };

  const getStartAmounts = async () => {
    // Logic to get user wallet group-wise data
    try {
      const response = await apiCall(API_URL +'get-start-amounts', {
          user_id: state.userData.id,
          token: token,
          nopaginate:1,
          //transfer_type:transferTypeFilter,
         // transaction_status:statusFilter
      });
      if (response.data.success == '0') {
        setStartAmounts(response.data.data);
      }
      
    } catch (error) {
        console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if(mt5TabType !== '' && !mt5TabTypeDefault){
     getUserMt5Accounts();
    }
  }, [mt5TabType]);


  useEffect(() => {
    if(mt5Type !== ''){
      setProductId(null);
      setProducts(null);
      setAccountTypeId(null);
      setAccountTypes(null);
      setLeverage(null);
      setLeverages(null);
      setStartAmount(null);
      setStartAmounts(null);
      setChangeOption(true);
      setSelectedProduct(null);
      setSelectedProductName(null);
    }
  }, [mt5Type]);

  useEffect(() => {
    console.log('changeOption');
    console.log(changeOption);
    if(changeOption === true){
      getProducts()
      getAccountTypes();
      getLeverages();
      getStartAmounts();
    }
  }, [changeOption]);

  useEffect(() => {
    if(sortType !== '' && !sortTypeDefault){
     getUserMt5Accounts();
    }
  }, [sortType]);

  useEffect(() => {
    if(sortOrder !== '' && !sortOrderDefault){
     getUserMt5Accounts();
    }
  }, [sortOrder]);
  useEffect(() => {
  

      fetchBanner();
    }, []);

  useEffect(() => {
    if(selectedProduct){
      setSelectedProductName(selectedProduct.name);
    }
  }, [selectedProduct]);

  const handleTypeChange = (type) => {
    setMt5TabTypeDefault(false);
    setMt5TabType(type)
  };

  const handleSortTypeChange = (sortType) => {
    setSortTypeDefault(false);
    setSortType(sortType)
  };
  const handleSortOrderChange = (sortOrder) => {
    setSortOrderDefault(false);
    setSortOrder(sortOrder)
  };
  const handleProductChange = (product) => {
   setOptionDisabled('');
   setProductId(product);
   setSelectedProduct(products.find((prod) => prod.id === product));
   setErrors((prev) => ({ ...prev, product_id: '' }));
  }
  const handleAccountTypeChange = (accountType) => {
    setAccountTypeId(accountType)
    setErrors((prev) => ({ ...prev, account_type_id: '' }));
  }
  const handleLeverageChange = (leverage) => {
    setLeverage(leverage)
    setErrors((prev) => ({ ...prev, leverage: '' }));
  }
  const handleUpdatedLeverageChange = (leverage) => {
    setUpdatedLeverageId(leverage)
    setLeverageErrors((prev) => ({ ...prev, leverage_id: '' }));
  }
  const handleStartAmountChange = (startAmount) => {
    setStartAmount(startAmount)
    setErrors((prev) => ({ ...prev, start_amount: '' }));
  }
  const validateFields = () => {
    const newErrors = {};
    if (!productId) {
      newErrors.product_id = 'Product is required';
    } 
    if (!accountTypeId) {
      newErrors.account_type_id = 'Acoount type is required';
    }
    if (!leverage) {
      newErrors.leverage = 'Leverage is required';
    } 
    if (mt5Type == 'demo' && !startAmount) {
      newErrors.start_amount = 'Start amount is required';
    } 
    
    return newErrors;
  };
  // ---
  const handleSubmit = async() => {
    const valErrors = validateFields();
  
    if (Object.keys(valErrors).length === 0) {
      // Prevent further submissions while processing
      if (isSubmitting) return; // Check if submission is already in progress
      setIsSubmitting(true); // Set submitting state to true

      // Create a FormData object
      const formData = new FormData();
      formData.append('user_id',state.userData?.id);
      formData.append('token',state?.token);
      formData.append('mt5_type',mt5Type);
      formData.append('product_id',productId);
      formData.append('account_type_id',accountTypeId);
      formData.append('leverage_id',leverage);
      formData.append('start_amount_id',startAmount);
      
      try {
        // Send FormData using Axios
        setIsBtnLoading(true);
        setRequestSubmitSuccsess(false);
        setRequestSubmitError(false);
      
      const response = await apiCall(API_URL + 'create-mt5-account', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

      // Handle response
            if (response.data.success == '0') {
              setProductId(null);
              setAccountTypeId(null);
              setLeverage(null);
              setStartAmount(null);
              setRequestSubmitSuccsess(true);
              setRequestSubmitSuccsessMsg('MT5 account created successfully');
              handleAccountModalClose();
              getUserMt5Accounts();
            } else if (response.data.success == '2') {
              setRequestSubmitError(true);
              setRequestSubmitErrorMsg(response.data.message);
              setIsAccountModal(false);
            } else {
              setRequestSubmitError(true);
              setRequestSubmitErrorMsg(response.data.data.error);
            }
      
    } catch (error) {
      setRequestSubmitError(true);
      setRequestSubmitErrorMsg(error.response?.data?.message || 'An error occurred');
    } finally {
      setIsBtnLoading(false);
      setIsSubmitting(false); // Reset submitting state
    }
  } else {
    // Set errors to state
    setErrors(valErrors);
  }
};

  //rename account api
  const validateRenameFields = () => {
    const newErrors = {};
    if (!name || name.trim() == '') {
      newErrors.name = 'Name is required';
    } 
    return newErrors;
  };
  // ---
  const handleRenameSubmit = async() => {
    const valErrors = validateRenameFields();
    if (Object.keys(valErrors).length === 0) {
      // Create a FormData object
      const formData = new FormData();
      formData.append('user_id',state.userData?.id);
      formData.append('user_wallet_id',currentAccount.id);
      formData.append('token',state?.token);
      formData.append('name',name);
     
      try {
        // Send FormData using Axios
        setIsRenBtnLoading(true);
        setRequestSubmitSuccsess(false)
        setRequestSubmitError(false)
        apiCall(API_URL + 'rename-mt5-account', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
            if(response.data.success == '0'){
              setAccountId(null);
              setName(null)
              setRequestSubmitSuccsess(true)
              setRequestSubmitSuccsessMsg('Mt5 account renamed successfully')
              handleAccountRenameClose();
              getUserMt5Accounts();
            } else {
              setRequestSubmitError(true)
              setRequestSubmitErrorMsg(response.data.data.error)
            }
            setIsRenBtnLoading(false);
        })
        .catch(error => {
          setIsRenBtnLoading(false);
          setRequestSubmitError(true)
          setRequestSubmitErrorMsg(error.response.data.message)
          //console.error('Error uploading profile picture:', error);
        });
      } catch (error) {
        //console.error('Error uploading files:', error);
      }
    } else {
      // Set errors to state
      setRenameErrors(valErrors);
    }
  };

  //rename account api
  const validateLeverageFields = () => {
    const newErrors = {};
    if (!updatedLeverageId || updatedLeverageId.trim() == '') {
      newErrors.leverage_id = 'Leverage is required';
    } 
    return newErrors;
  };
  // ---
  const handleLeverageSubmit = async() => {
    const valErrors = validateLeverageFields();
    if (Object.keys(valErrors).length === 0) {
      // Create a FormData object
      const formData = new FormData();
      formData.append('user_id',state.userData?.id);
      formData.append('user_wallet_id',currentAccount.id);
      formData.append('token',state?.token);
      formData.append('leverage_id',updatedLeverageId);
     
      try {
        // Send FormData using Axios
        setIsLevBtnLoading(true);
        setRequestSubmitSuccsess(false)
        setRequestSubmitError(false)
        apiCall(API_URL + 'change-mt5-leverage', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
            if(response.data.success == '0'){
              setAccountId(null);
              setUpdatedLeverageId(null)
              setRequestSubmitSuccsess(true)
              setRequestSubmitSuccsessMsg('Mt5 account leverage updated successfully')
              handleAccountLeverageClose();
              getUserMt5Accounts();
            } else {
              setRequestSubmitError(true)
              setRequestSubmitErrorMsg(response.data.data.error)
            }
            setIsLevBtnLoading(false);
        })
        .catch(error => {
          setIsLevBtnLoading(false);
          setRequestSubmitError(true)
          setRequestSubmitErrorMsg(error.response.data.message)
          //console.error('Error uploading profile picture:', error);
        });
      } catch (error) {
        //console.error('Error uploading files:', error);
      }
    } else {
      // Set errors to state
      setLeverageErrors(valErrors);
    }
  };

  const makeAccountArchived = async(accountId) => {
    const formData = new FormData();
      formData.append('user_id',state.userData?.id);
      formData.append('user_wallet_id',accountId);
      formData.append('token',state?.token);
      try {
        // Send FormData using Axios
        
        setRequestSubmitSuccsess(false)
        setRequestSubmitError(false)
        apiCall(API_URL + 'archive-mt5', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
            if(response.data.success == '0'){
              setRequestSubmitSuccsess(true)
              setRequestSubmitSuccsessMsg('Mt5 account archived successfully')
              getUserMt5Accounts();
            } else {
              setRequestSubmitError(true)
              setRequestSubmitErrorMsg(response.data.data.error)
            }
           
        })
        .catch(error => {
          setRequestSubmitError(true)
          setRequestSubmitErrorMsg(error.response.data.message)
          //console.error('Error uploading profile picture:', error);
        });
      } catch (error) {
        //console.error('Error uploading files:', error);
      }
  }
  const makeAccountReactivate = async(accountId) => {
    const formData = new FormData();
    formData.append('user_id',state.userData?.id);
    formData.append('user_wallet_id',accountId);
    formData.append('token',state?.token);
    try {
      // Send FormData using Axios
      setRequestSubmitSuccsess(false)
      setRequestSubmitError(false)
      apiCall(API_URL + 'reactivate-archived-mt5', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => {
          if(response.data.success == '0'){
            setRequestSubmitSuccsess(true)
            setRequestSubmitSuccsessMsg('Mt5 account reactivated successfully')
            getUserMt5Accounts();
          } else {
            setRequestSubmitError(true)
            setRequestSubmitErrorMsg(response.data.data.error)
          }
         
      })
      .catch(error => {
        setRequestSubmitError(true)
        setRequestSubmitErrorMsg(error.response.data.message)
        //console.error('Error uploading profile picture:', error);
      });
    } catch (error) {
      //console.error('Error uploading files:', error);
    }
  }
  const changePasswordRandom = async() =>{
    const formData = new FormData();
    formData.append('user_id',state.userData?.id);
    formData.append('user_wallet_id',accountId);
    formData.append('token',state?.token);
    formData.append('type',passwordType);
    
    try {
      // Send FormData using Axios
      setIsChangePwdRandomLoading(true);
      setRequestSubmitSuccsess(false)
      setRequestSubmitError(false)
      apiCall(API_URL + 'change-mt5-password-randomly', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => {
          if(response.data.success == '0'){
            setRequestSubmitSuccsess(true)
            setRequestSubmitSuccsessMsg('Mt5 account password updated successfully. Check your email for details.')
            setIsAccountPassword(false);
          } else {
            setRequestSubmitError(true)
            setRequestSubmitErrorMsg(response.data.data.error)
          }
          setIsChangePwdRandomLoading(false);
      })
      .catch(error => {
        setRequestSubmitError(true)
        setRequestSubmitErrorMsg(error.response.data.message)
        //console.error('Error uploading profile picture:', error);
      });
    } catch (error) {
      //console.error('Error uploading files:', error);
    }
  }
  const sendChangePwdOtp = async() =>{
    const formData = new FormData();
    formData.append('user_id',state.userData?.id);
    formData.append('user_wallet_id',accountId);
    formData.append('token',state?.token);
    formData.append('type',passwordType);
    
    try {
      // Send FormData using Axios
      setIsChangePwdRandomLoading(true);
      setRequestSubmitSuccsess(false)
      setRequestSubmitError(false)
      apiCall(API_URL + 'send-mt5-change-password-otp', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => {
          if(response.data.success == '0'){
            setRequestSubmitSuccsess(true)
            setRequestSubmitSuccsessMsg('Mt5 account password otp sent successfully. Check your email for otp.')
            setPasswordStep('password-step3');
          } else {
            setRequestSubmitError(true)
            setRequestSubmitErrorMsg(response.data.data.error)
          }
          setIsChangePwdRandomLoading(false);
      })
      .catch(error => {
        setRequestSubmitError(true)
        setRequestSubmitErrorMsg(error.response.data.message)
        //console.error('Error uploading profile picture:', error);
      });
    } catch (error) {
      //console.error('Error uploading files:', error);
    }
  }
  const validateOtpVerificationFields = () => {
    const newErrors = {};
    if (!otpVerify || otpVerify.trim() == '') {
      newErrors.otp = 'Otp is required';
    } 
    return newErrors;
  };
  const verifyOtp = async() =>{
    const valErrors = validateOtpVerificationFields();
    if (Object.keys(valErrors).length === 0) {
      const formData = new FormData();
      formData.append('user_id',state.userData?.id);
      formData.append('user_wallet_id',accountId);
      formData.append('token',state?.token);
      formData.append('otp',otpVerify);
      try {
        // Send FormData using Axios
        setIsOtpVerifyLoading(true);
        setRequestSubmitSuccsess(false)
        setRequestSubmitError(false)
        apiCall(API_URL + 'verify-mt5-change-password-otp', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
            if(response.data.success == '0'){
              setOtp(['','','',''])
              setOtpVerify(null);
              setRequestSubmitSuccsess(true)
              setRequestSubmitSuccsessMsg('Otp verified successfully')
              setPasswordStep('password-step4');
            } else {
              setRequestSubmitError(true)
              setRequestSubmitErrorMsg(response.data.data)
            }
            setIsOtpVerifyLoading(false);
        })
        .catch(error => {
          setRequestSubmitError(true)
          setRequestSubmitErrorMsg(error.response.data.message)
          //console.error('Error uploading profile picture:', error);
        });
      } catch (error) {
        //console.error('Error uploading files:', error);
      }
    } else {
      // Set errors to state
      setOtpVerificationErrors(valErrors);
    }
  }
  const handlePopupOptionClick = (option,accountId) => {
    setAccountId(accountId);
    if(option == 'info'){
      setIsAccountInfo(true);
    } else if (option =='rename'){
      setIsAccountRename(true);
    } else if (option =='password'){
      setPasswordStep('password-step1');
      setIsAccountPassword(true);
    } else if (option =='archive'){
      makeAccountArchived(accountId)
    } else if (option =='reactivate'){
      makeAccountReactivate(accountId);
    } else if (option =='leverage'){
      setIsAccountLeverage(true);
    } 
  }
  const validateChangePassswordFields = () => {
    const newErrors = {};
    if (!newPassword) {
      newErrors.new_password = 'New password is required';
    } else if(newPassword != '' && newPassword.length < 8){
      newErrors.new_password = 'Minimum 8 characters required';
    } else if(newPassword != ''){
      const hasUpperCase = /[A-Z]/.test(newPassword);
      const hasLowerCase = /[a-z]/.test(newPassword);
      const hasTwoLowerCases = (newPassword.match(/[a-z]/g) || []).length >= 2;
      const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(newPassword);
      const hasNumber = /[0-9]/.test(newPassword);
      if (!hasUpperCase || !hasLowerCase || !hasTwoLowerCases || !hasSymbol || !hasNumber) {
        newErrors.new_password = 'Password required atleast 1 upper,1 number,1 special character and 1 lower case';
      }
      
    }
    if (!confirmPassword) {
      newErrors.confirm_password = 'Confirm password is required';
    } else if(confirmPassword != '' && confirmPassword.length < 6){
      newErrors.confirm_password = 'Minimum 6 characters required';
    } else if(confirmPassword != newPassword){
      newErrors.confirm_password = 'New Password and confirm passwords did not matched';
    }


    return newErrors;
  };
  const handlePasswordUpdate = async() =>{
    const valErrors = validateChangePassswordFields();
    if (Object.keys(valErrors).length === 0) {
      const formData = new FormData();
      formData.append('user_id',state.userData?.id);
      formData.append('user_wallet_id',accountId);
      formData.append('token',state?.token);
      formData.append('password',newPassword);
      formData.append('password_confirmation',confirmPassword);
      try {
        // Send FormData using Axios
        setIsChangePwdLoading(true);
        setRequestSubmitSuccsess(false)
        setRequestSubmitError(false)
        apiCall(API_URL + 'change-mt5-password', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
            if(response.data.success == '0'){
              setNewPassword(null);
              setConfirmPassword(null);
              setRequestSubmitSuccsess(true);
              setRequestSubmitSuccsessMsg('Password updated successfully')
             // setPasswordStep('password-step4');
             setIsAccountPassword(false);
            } else {
              setRequestSubmitError(true);
              setRequestSubmitErrorMsg(response.data.data.error)
            }
            setIsChangePwdLoading(false);
        })
        .catch(error => {
          setRequestSubmitError(true)
          setRequestSubmitErrorMsg(error.response.data.message)
          //console.error('Error uploading profile picture:', error);
        });
      } catch (error) {
        //console.error('Error uploading files:', error);
      }
    } else {
      // Set errors to state
      setChangePwdErrors(valErrors);
    }
  }
  

  useEffect(() => {
    if(accountId){
      setCurrentAccount(mt5Accounts.find(account => account.id === accountId));
    }
  }, [accountId]);

  const handleNameChange = (name) => {
    setName(name)
    setRenameErrors((prev) => ({ ...prev, name: '' }));
  }

  const handleNewPasswordChange  = (event) => {
    setNewPassword(event.target.value)
    setChangePwdErrors((prev) => ({ ...prev, new_password: '' }));
  }

  const handleConfirmPasswordChange  = (event) => {
    setConfirmPassword(event.target.value)
    setChangePwdErrors((prev) => ({ ...prev, confirm_password: '' }));
  }

  const handlePasswordTypeChange = (event) => {
    setPasswordType(event.target.value)
  }

  const handlePasswordMethodChange = (event) =>{
    setPasswordMethod(event.target.value)
  } 

  const sortOptions = ['Default','Name','Balance'];

  // --- Payment section Tabbing Js Start
  const [createStepTab, setCreateStepTab] = useState('create-demo');

  const handleCreateStepTabClick = (mt5Type) => {
    setMt5Type(mt5Type)
    setChangeOption(false);
    //setCreateStepTab(createStepTabId);
  };
  // --- Payment section Tabbing Js End

  // --- Password Step section Tabbing Js Start
  const [passwordStep, setPasswordStep] = useState('password-step1');

  const handlePasswordStepClick = (passwordStepId) => {
    if(passwordStepId == 'password-step3'){
      if(passwordMethod == 'random'){
        changePasswordRandom();
      } else {
        sendChangePwdOtp();
        //setPasswordStep(passwordStepId);
      }
    } else if(passwordStepId == 'password-step4'){
      verifyOtp();
    } else {
      setPasswordStep(passwordStepId);
    }
  };
  // --- Password Step section Tabbing Js End

  // // ---
  // const ProductOptions = [
  //   { heading: "MT5 demo 1", selected: false },
  //   { heading: "MT5 demo 2", selected: false },
  //   { heading: "MT5 demo 3", selected: false },
  //   { heading: "MT5 demo 4", selected: false },
  // ];
  // const AccountTypeOptions = [
  //   { heading: "Hedging", selected: true },
  //   { heading: "Standard", selected: false },
  // ];
  // const LeverageOptions = [
  //   { name: "1:500", selected: false },
  //   // { heading: "1:600", selected: false },
  //   // { heading: "1:700", selected: false },
  //   // { heading: "1:800", selected: false },
  //   // { heading: "1:900", selected: false },
  //   // { heading: "1:1000", selected: false },
  // ];
  // const StartAmountOptions = [
  //   { heading: "1000", selected: false },
  //   { heading: "5000", selected: false },
  //   { heading: "10000", selected: false },
  //   { heading: "50000", selected: false },
  //   { heading: "100000", selected: false },
  // ];
  // ---


  // ---
  const [isAccountModal, setIsAccountModal] = useState(false);

  const handleAccountModalClose = () => {
    setIsAccountModal(false);
  };
    
  const handleAccountModalShow = () => {
    setIsAccountModal(true);
    // setCreateStepTab('create-demo');
    handleCreateStepTabClick(mt5TabType);
  }
  // ---

  // ---
  const [isAccountInfo, setIsAccountInfo] = useState(false);

  const handleAccountInfoClose = () => {
    setIsAccountInfo(false);
  };

  const handleAccountInfoShow = () => {
    setIsAccountInfo(true);
  }
  // ---

  // ---
  const [isAccountRename, setIsAccountRename] = useState(false);

  const handleAccountRenameClose = () => {
    setIsAccountRename(false);
  };

  const handleAccountRenameShow = () => {
    setIsAccountRename(true);
  }
  // ---

  // ---
  const [isAccountLeverage, setIsAccountLeverage] = useState(false);

  const handleAccountLeverageClose = () => {
    setIsAccountLeverage(false);
  };

  const handleAccountLeverageShow = () => {
    setIsAccountLeverage(true);
  }
  // ---

  // ---
  const [isAccountPassword, setIsAccountPassword] = useState(false);

  const handleAccountPasswordClose = () => {
    setIsAccountPassword(false);
  };

  const handleAccountPasswordShow = () => {
    setIsAccountPassword(true);
    setPasswordStep('password-step1');
  }
  // ---

  // ---
  const [isTrade, setIsTrade] = useState(false);

  const handleTradeClose = () => {
    setIsTrade(false);
    setCurrentAccountId(null);
  };
 
  const handleTradeShow = (accountId) => {
    setCurrentAccountId(accountId);
    
  }
  useEffect(() => {
    if(currentAccountId){
      setIsTrade(true);
    }
  }, [currentAccountId]);

  // ---

  // ---
  const [isCredential, setIsCredential] = useState(false);
  const [accountData, setAccountData] = useState(null);

  const handleCredentialClose = () => {
    setIsCredential(false);
  };
 
  const handleCredentialShow = (account) => {
    setIsCredential(true);
    setAccountData(account);
  }
  // ---


  // ---
  const [otp, setOtp] = useState(['', '', '', '']);
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];

  const handleChange = (e, index) => {
    const value = e.target.value;

    if (!isNaN(value) && value.length <= 1) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      setOtpVerify(newOtp.join(''));
      if (index < otp.length - 1 && value !== '') {
        inputRefs[index + 1].current.focus();
      }
    } else if (value === '' && index > 0) {
      // If backspace is pressed and the input field is empty, move focus to the previous input
      inputRefs[index - 1].current.focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace') {
        e.preventDefault();
        if (index > 0) {
        // Move focus to the previous input
        inputRefs[index - 1].current.focus();
        }
        // Clear the current input
        const newOtp = [...otp];
        newOtp[index] = '';
        setOtp(newOtp);
    }
  };

  const handleHistoryClick = (accountId) => {
    navigate('/mt5-trade-history/'+accountId);
  }
  // ---


  // View passwords code
  const [showPasswords, setShowPasswords] = useState([false, false, false]);

  const togglePasswordVisibility = (index) => {
  const updatedShowPasswords = [...showPasswords];
  updatedShowPasswords[index] = !updatedShowPasswords[index];
  setShowPasswords(updatedShowPasswords);
  };

  

  return (
    <>
    <Breadcrumb breadcrumbIcon="Mt5Svg" breadcrumbHeading="MT5" />

    <Link to="https://www.youtube.com/watch?v=zD0c69uj_Yw" target="_blank" className="video-link-btn vlb-big me-4">
      <div className="vlb-svg" ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path fillRule="evenodd" d="M4.5 5.653c0-1.427 1.529-2.33 2.779-1.643l11.54 6.347c1.295.712 1.295 2.573 0 3.286L7.28 19.99c-1.25.687-2.779-.217-2.779-1.643V5.653Z" clipRule="evenodd" /></svg></div>
      <div className="vlb-text" >How to create account</div>
    </Link>

    <div className="container-lg pt-3 pb-5 px-lg-4">
      <div className="row row-gap-4">

       <div className="col-12">
          {loading ? (
            <div className="skeletant-bx skeletant-design sh-95"></div>
          ) : (
            bannerImg ? (
              // <BannerImg src={bannerImg} />
              <BannerImg src={bannerImg.imageUrl} link={bannerImg.link} />
            ) : ''
          )}
        </div>

        <div className="col-12">


            {/* --- Filter Section Start --- */}
            <div className="cb-p-bg-none mb-4">
              <div className="common-tabs-bx mb-4">
                  <div className={`ctb-item ${mt5TabType == 'real' ? 'active':''}`} onClick={() => handleTypeChange('real')}>Real</div>
                  <div className={`ctb-item ${mt5TabType == 'demo' ? 'active':''}`} onClick={() => handleTypeChange('demo')}>Demo</div>
                  {/* <div className={`ctb-item ${mt5TabType == 'archived' ? 'active':''}`} onClick={() => handleTypeChange('archived')}>Archived</div> */}
              </div>

              <div className="filter-bx">
                {(mt5Accounts && mt5Accounts.length < 3) && 
                  <div onClick={handleAccountModalShow} className="common-btn-item cbi-fill"><span><Icons.PlusSvg/>Add Account</span></div>
                }
                
                {/* <div className="filter-right d-flex flex-wrap gap-2 justify-content-between align-items-center">
                  <FilterDropItem ascending={true} onSortChange={handleSortOrderChange} label={sortType} groupName="DefaultType" options={sortOptions} onChange={handleSortTypeChange} selected={sortType} /> */}
                  {/* <div className="common-btn-item cbi-fill"><span><Icons.ArrowexportSvg />Export</span></div>   */}
                {/* </div> */}
                
              </div>
            </div>
            {/* --- Filter Section End --- */}

            {/* --- MTs Account Section Start --- */}
            <div className="common-box cb-p-bg-none">
              <div className="row row-gap-4">
              {mt5Accounts && mt5Accounts.length > 0 && !isLoading &&
                mt5Accounts.map((account, index) => (
                  <MT5AccountCard 
                  AcInfoClick={()=>handlePopupOptionClick('info',account.id)} 
                  AcRenameClick={()=>handlePopupOptionClick('rename',account.id)} 
                  AcPasswordClick={()=>handlePopupOptionClick('password',account.id)}
                  AcLeverageClick={()=>handlePopupOptionClick('leverage',account.id)} 
                  AcArchiveClick={()=>handlePopupOptionClick('archive',account.id)}
                  AcReactivateClick={()=>handlePopupOptionClick('reactivate',account.id)}
                  AcHistoryClick={()=>handleHistoryClick(account.id)}
                  AcTradeClick={()=>handleTradeShow(account.id)}
                  AcCredentialClick={()=>handleCredentialShow(account)}
                  key={index}
                  data={account}
                  currencyInrRate = {currencyInrRate}
                  caption ={caption}
                  displayStatusFilter={filters[account.id] || 'USD'}
                  onStatusChange={(status) => handleStatusChange(account.id, status)}
                />
                ))
              }
              {mt5Accounts && mt5Accounts.length == 0 && !isLoading &&
                <EmptyBox
                className="empty-div-bx no-invert" 
                img={empty4}
                eh="No accounts found" 
                esh="You don’t have any demo account"
                buttononClick = {handleAccountModalShow}
                buttonClickText = "Open New Account"
              />
              }
              {isLoading &&
              <>
                  <div className="col-lg-6 col-sm-6">
                      <div className="skeletant-bx skeletant-design sh-245"></div>
                  </div>
                  <div className="col-lg-6 col-sm-6">
                      <div className="skeletant-bx skeletant-design sh-245"></div>
                  </div>
                  <div className="col-lg-6 col-sm-6">
                      <div className="skeletant-bx skeletant-design sh-245"></div>
                  </div>
                  <div className="col-lg-6 col-sm-6">
                      <div className="skeletant-bx skeletant-design sh-245"></div>
                  </div>
                  <div className="col-lg-6 col-sm-6">
                      <div className="skeletant-bx skeletant-design sh-245"></div>
                  </div>
                  <div className="col-lg-6 col-sm-6">
                      <div className="skeletant-bx skeletant-design sh-245"></div>
                  </div>
                  <div className="col-lg-6 col-sm-6">
                      <div className="skeletant-bx skeletant-design sh-245"></div>
                  </div>
              </>
              }

                {/* <MT5AccountCard 
                  AcInfoClick={handleAccountInfoShow} 
                  AcRenameClick={handleAccountRenameShow} 
                  AcPasswordClick={handleAccountPasswordShow} 
                />
                <MT5AccountCard 
                  AcInfoClick={handleAccountInfoShow} 
                  AcRenameClick={handleAccountRenameShow} 
                  AcPasswordClick={handleAccountPasswordShow} 
                />
                <MT5AccountCard 
                  AcInfoClick={handleAccountInfoShow} 
                  AcRenameClick={handleAccountRenameShow} 
                  AcPasswordClick={handleAccountPasswordShow} 
                /> */}

                {/* <EmptyBox 
                  className="empty-div-bx" 
                  eh="No accounts found" 
                  esh="You don’t have any demo accounts"
                  buttononClick = {handleAccountModalShow}
                  buttonClickText = "Open New Account"
                /> */}

              </div>
            </div>
            {/* --- MTs Account Section End --- */}

        </div>
      </div>
    </div>

    {/* --- Open New Account Modal --- */}
    <Modal className="zoom custom-content" centered show={isAccountModal} onHide={handleAccountModalClose}>
      <div className="custom-modal-header">
          {/* {createStepTab === 'create-account' ? (
            <div className="cmh-lable">Open New Account</div>
          ) : createStepTab === 'create-demo' ? (
            <div className="cmh-lable">Open Demo Account</div>
          ) : createStepTab === 'create-live' ? (
            <div className="cmh-lable">Open Real Account</div>
          ) : null } */}

          <div className="cmh-lable small-cmh-lable">Open New Account</div>
 

          <span className="close-icon" onClick={handleAccountModalClose}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368"></path>
              </svg>
          </span>
      </div>

      <div className="custom-modal-body">

          <div className="create-account-bx">
            <div className="create-account-btns-bx">
              <div className={`cabb-item ${mt5Type === 'real' ? 'active' : ''}`} onClick={() => handleCreateStepTabClick('real')} >Real Account</div>
              <div className={`cabb-item ${mt5Type === 'demo' ? 'active' : ''}`} onClick={() => handleCreateStepTabClick('demo')} >Demo Account</div>
            </div>
          </div>

          <div className={`create-account-bx ${createStepTab === 'create-demo' ? '' : 'd-none'}`}
          id="create-demo"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault(); // Prevent default form submission
              handleSubmit(); // Call your submit handler
            }
          }}
          tabIndex={0} // Make the div focusable
        >
            <div className="row mt-2 sg-fem-style">
              <div className="col-sm-6">
                <SelectDropItem
                    className={errors.product_id ? "error-group" : ""}
                    label="Product"
                    heading={"Select Product"}
                    options={products}
                    onChange={handleProductChange}
                    selectedOption={selectedProductName}
                />
                 {errors.product_id && <span className="form-error-msg">{errors.product_id}</span>}
              </div>
              <div className="col-sm-6">
                <SelectDropItem
                    className={errors.account_type_id ? `error-group ${optionDisabled}` : `${optionDisabled}`}
                    label="Account Type"
                    heading="Select Account Type"
                    options={accountTypes}
                    onChange={handleAccountTypeChange}
                />
                 {errors.account_type_id && <span className="form-error-msg">{errors.account_type_id}</span>}
              </div>
              <div className="col-sm-6">
                <SelectDropItem
                    className={`cdi-view-bl ${errors.leverage ? `error-group ${optionDisabled}` : `${optionDisabled}`}`} 
                    label="Leverage"
                    heading="Select Leverage"
                    options={leverages}
                    onChange={handleLeverageChange}
                />
                 {errors.leverage && <span className="form-error-msg">{errors.leverage}</span>}
              </div>
              <div className={`col-sm-6 ${mt5Type == 'real' ? 'd-none' : ''}`}>
                <SelectDropItem
                    className={`cdi-view-bl ${errors.start_amount ? `error-group ${optionDisabled}` : `${optionDisabled}`}`}  
                    label="Start Amount"
                    heading="Select Start Amount"
                    options={startAmounts}
                    onChange={handleStartAmountChange}
                />
                 {errors.start_amount && <span className="form-error-msg">{errors.start_amount}</span>}
              </div>
              <div className="col-12 mt-2">
              {!isBtnLoading && (
                <div className="common-submit-btn" onClick={handleSubmit}>
                  Submit
                </div>
              )}
              {isBtnLoading && (
                <div className="lsb-loader-btn">
                   <img src={Img.loading} alt="" />Please wait...
                </div>
               )}
            </div>
          </div>
    </div>

          <div className={`create-account-bx ${createStepTab === 'create-live' ? '' : 'd-none'}`} id="create-live">
            <div className="row mt-2 sg-fem-style">
              <div className="col-sm-6">
                <SelectDropItem 
                    label="Product"
                    className={errors.product_id ? "error-group" : ""}
                    heading="Select product"
                    options={products}
                    onChange={handleProductChange}
                />
                  {errors.product_id && <span className="form-error-msg">{errors.product_id}</span>}
              </div>
              <div className="col-sm-6">
                <SelectDropItem 
                    className="cdi-disabled"
                    label="Account Type"
                    heading="Select Account Type"
                    options={accountTypes}
                    onChange={handleAccountTypeChange}
                
                />
                {errors.account_type_id && <span className="form-error-msg">{errors.account_type_id}</span>}
              </div>
              <div className="col-sm-6">
                <SelectDropItem
                    className="cdi-view-bl cdi-disabled" 
                    label="Leverage"
                    heading="Select Leverage"
                    options={leverages}
                    onChange={handleLeverageChange}
                />
                {errors.leverage && <span className="form-error-msg">{errors.leverage}</span>}
              </div>
              {/* <div className="col-sm-6">
                <SelectDropItem 
                    className="cdi-view-bl cdi-disabled" 
                    label="Start Amount"
                    heading="Select Start Amount"
                    options={startAmounts}
                    onChange={handleStartAmountChange}
                />
                {errors.start_amount && <span className="form-error-msg">{errors.start_amount}</span>}
              </div> */}
              <div className="col-12 mt-2">
              {!isBtnLoading && <div className="common-submit-btn" onClick={handleSubmit}>Submit</div>
              }

              {isBtnLoading && <div className="lsb-loader-btn">
                <img src={Img.loading} alt="" />Please wait...
              </div>
              }
                 
              </div>
            </div>
          </div>
          
      </div>
    </Modal>

    {/* --- Account Info Modal --- */}
    <Modal className="zoom custom-content" centered show={isAccountInfo} onHide={handleAccountInfoClose}>
      <div className="custom-modal-header">
        
          <div className="cmh-lable">Account Information</div>
 
          <span className="close-icon" onClick={handleAccountInfoClose}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368"></path>
              </svg>
          </span>
      </div>

      <div className="custom-modal-body">

          <div className="ac-info-item">

            <div className="ai-inner">
              <div className="ai-i-info">Account</div>
              <div className="ai-i-data">{currentAccount?.account_number}</div>
            </div>

            <div className="ai-inner">
              <div className="ai-i-info">Nickname</div>
              <div className="ai-i-data">{currentAccount?.code}</div>
            </div>

            <div className="ai-inner">
              <div className="ai-i-info">Product</div>
              <div className="ai-i-data">{currentAccount?.product?.name}</div>
            </div>

            <div className="ai-inner">
              <div className="ai-i-info">Type</div>
              <div className="ai-i-data">{currentAccount?.account_type?.name}</div>
            </div>

            <div className="ai-inner">
              <div className="ai-i-info">Real funds</div>
              <div className="ai-i-data">{currentAccount?.formatted_balance} {currentAccount?.currency?.code}</div>
            </div>

            <div className="ai-inner">
              <div className="ai-i-info">Leverage</div>
              <div className="ai-i-data">{currentAccount?.leverage.value}</div>
            </div>

          </div>

      </div>
    </Modal>

    {/* --- Account Rename Modal --- */}
    <Modal className="zoom custom-content" centered show={isAccountRename} onHide={handleAccountRenameClose}>
      <div className="custom-modal-header">
        
          <div className="cmh-lable">Rename your account</div>
          <div className="cmh-sub-lable">If you have multiple accounts, you can give each one a unique name to make navigation easier.</div>
 
          <span className="close-icon" onClick={handleAccountRenameClose}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368"></path>
              </svg>
          </span>
      </div>

      <div
        className="custom-modal-body"
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault(); // Prevent default behavior
            handleRenameSubmit(); // Call your submit function
          }
        }}
        tabIndex={0} // Make the div focusable
      >
        <div className="px-3 px-sm-4 pb-2">

          <div className="ac-info-item mx-0 py-2">
            <div className="ai-inner">
              <div className="ai-i-info">Account</div>
              <div className="ai-i-data">{currentAccount?.account_number}</div>
            </div>
          </div>

          <div className="row signinup-group-style sg-fem-style">

            <div className="col-12">
              <Input className={renameErrors.name ? "error-group" : ""} heading="Nickname" type="text" placeholder="Enter account nickname" onChange={handleNameChange}/>
              {renameErrors.name && <span className="form-error-msg">{renameErrors.name}</span>}
            </div>
          </div>
            
          <div>
              {!isRenBtnLoading && <div className="common-submit-btn" onClick={handleRenameSubmit}>Submit</div>
              }

              {isRenBtnLoading && <div className="lsb-loader-btn">
                <img src={Img.loading} alt="" />Please wait...
              </div>
              }
            
          </div>

        </div>
      </div>
    </Modal>

    {/* --- Account Change Password Modal --- */}
    <Modal className="zoom custom-content cc-mw396px " centered show={isAccountPassword} onHide={handleAccountPasswordClose}>
      <div className="custom-modal-header">
          {passwordStep === 'password-step1' ? (
            <>
              <div className="cmh-lable">Change Password</div>
              <div className="cmh-sub-lable">Select an account type for which you want to change the password</div>
            </>
          ) : passwordStep === 'password-step2' ? (
            <>
              <div className="cmh-lable">Change Password</div>
              <div className="cmh-sub-lable">Please choose the way you want to change the password:</div>
            </>
          ) : passwordStep === 'password-step3' ? (
            <>
              <div className="cmh-lable">Confirmation</div>
              <div className="cmh-sub-lable">Please, enter a verification code sent to your email</div>
            </>
          ) : passwordStep === 'password-step4' ? (
            <>
              <div className="cmh-lable">Set New Password</div>
              <div className="cmh-sub-lable">Create new strong password. After creating new password you will be logged out</div>
            </>
          ) : null }

          <div className={`CommonBackBtn ${passwordStep === 'password-step2' ? '' : 'd-none'}`} onClick={() => handlePasswordStepClick('password-step1')}>
            <Icons.LeftArrowSvg />
          </div>

          <span className="close-icon" onClick={handleAccountPasswordClose}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368"></path>
              </svg>
          </span>
      </div>

      <div
        className="custom-modal-body"
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            switch (passwordStep) {
              case 'password-step1':
                handlePasswordStepClick('password-step2');
                break;
              case 'password-step2':
                if (!isChangePwdRandomLoading) {
                  handlePasswordStepClick('password-step3');
                }
                break;
              case 'password-step3':
                if (!isOtpVerifyLoading) {
                  handlePasswordStepClick('password-step4');
                }
                break;
              case 'password-step4':
                if (!isChangePwdLoading) {
                  handlePasswordUpdate();
                }
                break;
              default:
                break;
            }
          }
        }}
        tabIndex={0} // Make the div focusable
      >
        {/* Step 1 */}
          <div className={`custom-modal-data px-3 pb-3 pt-2 ${passwordStep === 'password-step1' ? '' : 'd-none'}`} id="password-step1">
              <div className="row row-gap-3">
                <div className="col-12">
                  <RadioInput 
                    className="mb-1 sgc-radio" 
                    name="PasswordType" 
                    id="Master" 
                    label="Master Password"
                    labelNote = "The master password of the MetaTrader platform allows you full access over your trading account."
                    value="master" 
                    onChange={handlePasswordTypeChange}
                    checked={passwordType == 'master'}
                  />
                </div>
                <div className="col-12">
                  <RadioInput 
                    className="mb-1 sgc-radio"
                    name="PasswordType"
                    id="Investment"
                    label="Investor Password"
                    labelNote = "The investor (read-only) password provides limited access only. For example, if you login to the MetaTrader platform using your Investor password, you will be unable to place any trades but only to view opened/closed trades." 
                    value="investor"
                    onChange={handlePasswordTypeChange}
                    checked={passwordType == 'investor'}
                  />
                </div>
              </div>
              <div className="mt-3">
                <div className="common-submit-btn" onClick={() => handlePasswordStepClick('password-step2')}>Continue</div>
              </div>
          </div>

        {/* Step 2 */}
          <div className={`custom-modal-data px-3 pb-3 pt-2 ${passwordStep === 'password-step2' ? '' : 'd-none'}`} id="password-step2">
              <div className="row row-gap-3">
                <div className="col-12">
                  <RadioInput 
                    className="mb-1 sgc-radio" 
                    name="wayType" 
                    id="random" 
                    label="Random system password"
                    labelNote = "Recommended" 
                    value="random"
                    onChange={handlePasswordMethodChange}
                    checked={passwordMethod === 'random'}
                  />
                </div>
                <div className="col-12">
                  <RadioInput 
                    className="mb-1 sgc-radio"
                    name="wayType"
                    id="own"
                    label="Your own password"
                    value="manual"
                    onChange={handlePasswordMethodChange}
                    checked={passwordMethod === 'manual'}
                  />
                </div>
                <div className="col-12">
                  <div className="input-note-2"><Icons.InfoSvg /> After changing the password you will log out from all devices</div>
                </div>
              </div>
              <div className="mt-3">
                {!isChangePwdRandomLoading && <div className="common-submit-btn" onClick={() => handlePasswordStepClick('password-step3')}>Continue</div>
                }
                {isChangePwdRandomLoading && <div className="lsb-loader-btn">
                  <img src={Img.loading} alt="" />Please wait...
                </div> }
              </div>
          </div>

        {/* Step 3 */}
          <div className={`custom-modal-data px-3 pb-3 pt-2 ${passwordStep === 'password-step3' ? '' : 'd-none'}`} id="password-step3">
              <div className="row signinup-group-style">
                <div className="col-12">
                    <div className={`signinup-group otp-group`}>
                        {otp.map((digit, index) => (
                            <>
                              <input key={index}  inputMode="numeric" type="text" maxLength="1" placeholder=" " value={digit} onChange={(e) => handleChange(e, index)} onKeyDown={(e) => handleKeyDown(e, index)} ref={inputRefs[index]} />
                            </>
                        ))}
                    </div>
                    {otpVerificationErrors.otp && <span className="form-error-msg text-center">{otpVerificationErrors.otp}</span>}
                </div>
                <div className="col-12">
                    <div className="otp-resend-bx">
                        <div>Have Not Received Code</div>
                        {/* <div><div className="reotp-btn">Resend</div></div> */}
                        <div>Resend In <span>30s</span></div>
                    </div>
                </div>
                <div className="col-12 mt-2">
                {!isOtpVerifyLoading && <div className="common-submit-btn" onClick={() => handlePasswordStepClick('password-step4')}>Continue</div>
                }
                {isOtpVerifyLoading && <div className="lsb-loader-btn">
                    <img src={Img.loading} alt="" />Please wait...
                  </div> 
                }
                </div>
              </div>
          </div>

        {/* Step 4 */}
          <div className={`custom-modal-data px-3 pb-3 pt-2 ${passwordStep === 'password-step4' ? '' : 'd-none'}`} id="password-step4">
              <div className="row signinup-group-style">
                  <div className={`col-12`} key={0}>
                      <div className={`signinup-group sgr-icon ${changePwdErrors.new_password ? "error-group" : ""}`}>
                          <input
                              type={showPasswords[0] ? 'text' : 'password'}
                              placeholder={'New Password'}
                              onChange={handleNewPasswordChange}
                          />
                          <span className={`group_right_icon ${showPasswords[0] ? "active" : ""}`} onClick={() => togglePasswordVisibility(0)} >
                              {showPasswords[0] ? <Icons.ViewPassSvg /> : <Icons.HidePassSvg/>}
                          </span>
                          {changePwdErrors.new_password && <span className="form-error-msg">{changePwdErrors.new_password}</span>}
                      </div>
                  </div>
                  <div className={`col-12`} key={1}>
                      <div className={`signinup-group sgr-icon ${changePwdErrors.confirm_password ? "error-group" : ""}`}>
                          <input
                              type={showPasswords[1] ? 'text' : 'password'}
                              placeholder={'Confirm Password'}
                              onChange={handleConfirmPasswordChange}
                          />
                          <span className={`group_right_icon ${showPasswords[1] ? "active" : ""}`} onClick={() => togglePasswordVisibility(1)} >
                              {showPasswords[1] ? <Icons.ViewPassSvg /> : <Icons.HidePassSvg/>}
                          </span>
                          {changePwdErrors.confirm_password && <span className="form-error-msg">{changePwdErrors.confirm_password}</span>}
                      </div>
                  </div>
                <div className="col-12 mt-2">
                {!isChangePwdLoading && <div className="common-submit-btn" onClick={() => handlePasswordUpdate()}>Continue</div>
                }
                {isChangePwdLoading && <div className="lsb-loader-btn">
                    <img src={Img.loading} alt="" />Please wait...
                  </div> 
                }
                </div>
              </div>
          </div>
      </div>
    </Modal>

    {/* --- Account Leverage change Modal --- */}
    <Modal className="zoom custom-content" centered show={isAccountLeverage} onHide={handleAccountLeverageClose}>
      <div className="custom-modal-header">
        
          <div className="cmh-lable">Change Leverage</div>
          <div className="cmh-sub-lable">Change your account leverage.</div>
 
          <span className="close-icon" onClick={handleAccountLeverageClose}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368"></path>
              </svg>
          </span>
      </div>

      <div
        className="custom-modal-body"
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            if (!isLevBtnLoading) {
              handleLeverageSubmit();
            }
          }
        }}
        tabIndex={0} // Make the div focusable
      >

        <div className="px-3 px-sm-4 pb-2">

          <div className="ac-info-item mx-0 py-2">
            <div className="ai-inner">
              <div className="ai-i-info">Account</div>
              <div className="ai-i-data">{currentAccount?.account_number}</div>
            </div>
          </div>

          <div className="row signinup-group-style sg-fem-style">

            <div className="col-12">
            <SelectDropItem
                    className={`cdi-view-bl ${leverageErrors.leverage_id ? "error-group": ""}`} 
                    label="Leverage"
                    heading={currentAccount?.leverage ? currentAccount?.leverage?.name : 'Select Leverage'}
                    options={leverages}
                    onChange={handleUpdatedLeverageChange}

                />
              {leverageErrors.leverage_id && <span className="form-error-msg">{leverageErrors.leverage_id}</span>}
            </div>
          </div>
            
          <div>
              {!isLevBtnLoading && <div className="common-submit-btn" onClick={handleLeverageSubmit}>Submit</div>
              }

              {isLevBtnLoading && <div className="lsb-loader-btn">
                <img src={Img.loading} alt="" />Please wait...
              </div>
              }
            
          </div>

        </div>
      </div>
    </Modal>

    {/* --- Account Trade Modal --- */}
    <Modal className="zoom custom-content" centered show={isTrade} onHide={handleTradeClose}>
      <div className="custom-modal-header">
        
          <div className="cmh-lable">Trade</div>
 
          <span className="close-icon" onClick={handleTradeClose}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368"></path>
              </svg>
          </span>
      </div>

      <div className="custom-modal-body">
        <div className="px-3 px-sm-4 pb-4">

          <div className="row row-gap-3">

            {/* <div className="col-12">
              <Link to={"/trading/"+currentAccountId} className="trading-item" onClick={handleTradeClose}>
                  <img className="trading-i-img" src={Img.icon_light} alt="Winprofx" />               
                  <div>
                    <div className="trading-i-heading">Winprofx terminal</div>
                    <div className="trading-i-text">Trade directly from your browser</div>
                  </div>
                  <div className="trading-icon"><Icons.RightArrowSvg /></div>
              </Link>
            </div> */}

            <div className="col-12">
              <a download={true} href={tradeSteup} className="trading-item" onClick={handleTradeClose}>
                  <img className="trading-i-img" src={Img.mt5} alt="Winprofx" />               
                  <div>
                    <div className="trading-i-heading">MetaTrader 5</div>
                    <div className="trading-i-text">Download and install the MT5 platform</div>
                  </div>
                  <div className="trading-icon"><Icons.RightArrowSvg /></div>
              </a>
            </div>

          </div>

        </div>
      </div>
    </Modal>

    {/* --- Account Credential Modal --- */}
    <Modal className="zoom custom-content" centered show={isCredential} onHide={handleCredentialClose}>
      <div className="custom-modal-header">
        
          <div className="cmh-lable">Account Credentials</div>
          <div className="cmh-sub-lable">Account ID: {accountData ? accountData.account_number : ''}</div>
 
          <span className="close-icon" onClick={handleCredentialClose}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368"></path>
              </svg>
          </span>
      </div>

      <div className="custom-modal-body">
        <div className="cm-info-item px-3">
          
          <div className="cmi-inner cmi-fd-column cmi-copy">
            <div className="cmi-i-info">Account number</div>
            <div className="cmi-i-data">{accountData ? accountData.account_number : ''} <span className="cmi-copy-icon" onClick={() => copyClick(accountData ? accountData.account_number : '')}><Icons.CopySvg /></span></div>
          </div>

          <div className="cmi-inner cmi-fd-column cmi-copy">
            <div className="cmi-i-info">Password</div>
            <div className="cmi-i-data">{accountData ? accountData.master_password : ''} <span className="cmi-copy-icon" onClick={() => copyClick(accountData ? accountData.master_password : '')}><Icons.CopySvg /></span></div>
          </div>

          <div className="cmi-inner cmi-fd-column cmi-copy">
            <div className="cmi-i-info">Server</div>
            <div className="cmi-i-data">Winprofx-Live <span className="cmi-copy-icon" onClick={() => copyClick('Winprofx-Live')}><Icons.CopySvg /></span></div>
          </div>

          <div className="cmi-inner cmi-fd-column cmi-copy">
            <div className="cmi-i-info">Platform</div>
            <div className="cmi-i-data">Metatrader 5 <span className="cmi-copy-icon" onClick={() => copyClick('Metatrader 5')}><Icons.CopySvg /></span></div>
          </div>

        </div>

        <div className="px-3 pb-4">
          <a download={true} href={tradeSteup} className="trading-item" onClick={handleTradeClose}>
              <img className="trading-i-img" src={Img.mt5} alt="Winprofx" />               
              <div>
                <div className="trading-i-heading">MetaTrader 5</div>
                <div className="trading-i-text">Download and install the MT5 platform</div>
              </div>
              <div className="trading-icon"><Icons.RightArrowSvg /></div>
          </a>
        </div>

      </div>
    </Modal>


    {requestSubmitSuccess && <FlashMessage type="success" isvisible={true} message={requestSubmitSuccessMsg} />}
    {requestSubmitError && <FlashMessage type="error" isvisible={true} message={requestSubmitErrorMsg} />}

    </>
  )
}