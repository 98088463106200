import React, { useState, useEffect, useRef } from 'react';
import { useAuth, getLoggedInUserData, getToken } from '../../context/AuthContext';
import { Link, NavLink, useNavigate } from "react-router-dom";
import useApi from '../../utility/apiCall';
import API_URL from '../../config/config';
import FlashMessage from '../../components/FlashMessage'; 
// Images Common File
import * as Img from '../../components/Img'
import bannerImg from '../../img/banner/deposit.jpg'

// Design Files
import Breadcrumb from '../../components/Breadcrumb'
import BannerImg from "../../components/BannerImg";
import PaymentMethodCard from './PaymentMethodCard'
import Input from '../../components/CommonInput/Input'
import AccountDropItem from './AccountDropItem'
import { Modal } from 'react-bootstrap';

// AccountDropItem demo img
import walletIcon from "../../img/icons/paymentmethod/wallet.png";
import mt5 from "../../img/icons/paymentmethod/mt5.webp";

// Image
import USDTTRC20 from "../../img/icons/paymentmethod/USDT-TRC20.png"
import USDTBEP20 from "../../img/icons/paymentmethod/USDT-BEP20.png"
import USDTERC20 from "../../img/icons/paymentmethod/USDT-ERC20.png"
import bitcoin from "../../img/icons/paymentmethod/bitcoin.png"
import banktransfer from "../../img/icons/paymentmethod/bank-transfer.png"

export default function Withdraw({ wallet_id = null, wallet_type = null }) {
  const { apiCall } = useApi();
  const { state, dispatch } = useAuth();
  const token = state.token || '';
  const [wallet, setWallet] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const isInitialMount = useRef(true);

  const [otp, setOtp] = useState(["", "", "", ""]);
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];
  const [otpErrors, setOtpErrors] = useState({});
  const [otpSuccsess, setOtpSuccsess] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [otpSuccess, setOtpSuccess] = useState(false);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [currentCurrencyCode, setCurrentCurrencyCode] = useState('');
  const [currentCurrencyRate, setCurrentCurrencyRate] = useState(0);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showSendBtn, setShowSendBtn] = useState(false);
  const [requestSubmitSuccess, setRequestSubmitSuccsess] = useState(false);
  const [requestSubmitError, setRequestSubmitError] = useState(false);
  const [requestSubmitErrorMsg, setRequestSubmitErrorMsg] = useState(false);

  const [selectedMt5Account, setSelectedMt5Account] = useState(null);
  const [toMt5Account, setToMt5Account] = useState(null);
  const [mt5Accounts, setMt5Accounts] = useState(null);

  const [isOTP, setIsOTP] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showOtpLoader, setShowOtpLoader] = useState(false);
  const [isVerifyingOtp, setIsVerifyingOtp] = useState(false);
const [isSendingOtp, setIsSendingOtp] = useState(false);





  //withdraw fields
  const [type, setType]= useState('mt5');
  const [transactionType, setTransactionType] = useState('crypto');
  const [transferType, setTransferType] = useState('withdraw');
  const [currencyId, setCurrencyId] = useState(null);
  const [paymentMethodId, setPaymentMethodId] = useState(null);
  const [userWalletId, setUserWalletId] = useState(null);
  const [name, setName] = useState(null);
  const [amount, setAmount] = useState('');
  const [usdAmount, setUsdAmount] = useState('');
  const [transactionId, setTransactionId] = useState(null);
  //const [mt5Number, setMt5Number] = useState(null);
  const [btcWalletAddress, setBtcWalletAddress] = useState(null);
  const [usdtWalletAddress, setUsdtWalletAddress] = useState(null);
  const [bankName, setBankName] = useState(null);
  const [bankAddress, setBankAddress] = useState(null);
  const [ifscCode, setIfscCode] = useState(null);
  const [accountNumber, setAccountNumber] = useState(null);
  const [accountHolderName,setAccountHolderName] = useState(null);
  const [screenshot, setScreenshot] = useState(null);
  const [verificationLabel,setVerificationLabel] = useState('Level 0');
  const [verificationPer,setVerificationPer] = useState(0);
  const [verificationLoader,setVerificationLoader] = useState(false);
  const navigate = useNavigate();
  const [msg,setMsg] = useState(false);
  const [timer, setTimer] = useState(30);
  const [bannerImg, setBannerImg] = useState(null); 
  const [loading, setLoading] = useState(true);

  const handleOTPClose = () => {
    setIsOTP(false);
  };

  useEffect(() => {

    fetchBanner();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const loggedInUser = getLoggedInUserData();
      const isUserLoggedIn = !!loggedInUser;
      
      if (isUserLoggedIn) {
        if (loggedInUser.id && token) {
          try {
            // const verifiation = await fetchVerification();
            const wallet = await getUserWallet();
            const paymentMethods = await getPaymentMethods();
            // Assuming getUserWalletGroupWise returns the data needed for wallets and walletGroups
          } catch (error) {
            console.error('Failed to fetch wallet group data:', error);
          }
        }
      }
    };
   
    if (isInitialMount.current) {
      isInitialMount.current = false;
      fetchData();
      getAccounts();
    }
  }, [token]);

  const handleChange = (e, index) => {
    const value = e.target.value;

    if (!isNaN(value) && value.length <= 1) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (index < otp.length - 1 && value !== "") {
        inputRefs[index + 1].current.focus();
      }
    } else if (value === "" && index > 0) {
      // If backspace is pressed and the input field is empty, move focus to the previous input
      inputRefs[index - 1].current.focus();
    }
  };
  useEffect(() => {
    if (otp.every((element) => element.trim() !== "")) {
      setOtpErrors((prev) => ({ ...prev, otpError: "" }));
    }
  }, [otp]);

  useEffect(() => {
    if (otpSuccsess && timer > 0) {
      const countdown = setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
      return () => clearTimeout(countdown); // Cleanup timeout
    }
  }, [timer, otpSuccsess]);

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      e.preventDefault();
      if (index > 0) {
        // Move focus to the previous input
        inputRefs[index - 1].current.focus();
      }
      // Clear the current input
      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);
    }
  };

  const handalSendOtp = async () => {
    try {
      const valErrors = validateFields();
      // console.log(valErrors)
      if (Object.keys(valErrors).length === 0) {
        setShowOtpLoader(true);
        setShowLoader(true);
        setIsOTP(true);
        const response = await apiCall(
          API_URL + "send-user-verification-otp",
          {
            user_id: state?.userData?.id,
            token: state?.token,
            verification_type: "withdraw",
          },
          {}
        );
        if (response.data.success == "0") {
          setShowLoader(false);
          setOtpSuccsess(true);
          setTimer(30);  // Start the timer
        }
      } else {
        setErrors(valErrors)
      }
      
    } catch (error) {
      console.error("An error occurred");
      setShowLoader(false);
      
    } finally {
      setShowOtpLoader(false);
    }
  };
  
  const handalReSendOtp = async () => {
    
    setIsSendingOtp(true); // Set sending state to true

    try {
      setShowOtpLoader(true);
      setShowLoader(true);
      setOtpSuccsess(false);
      const response = await apiCall(
        API_URL + "send-user-verification-otp",
        {
          user_id: state?.userData?.id,
          token: state?.token,
          verification_type: "withdraw",
        },
        {}
      );
      if (response.data.success == "0") {
        setShowLoader(false);
        setOtpSuccsess(true);
        setTimer(30);  // Start the timer
      }
    } catch (error) {
      console.error("An error occurred");
      setShowLoader(false);
      
    } finally {
        setIsSendingOtp(false); // Reset sending state
        setShowOtpLoader(false);
    }
};


  const handalVerifyOtp = async () => {
    const valErrors = validateOtpFields();
    console.log(valErrors);
  
    if (isVerifyingOtp) return; // Prevent multiple verification submissions
    setIsVerifyingOtp(true); // Set verifying state to true

    if (Object.keys(valErrors).length === 0) {
      setShowOtpLoader(true);
      setOtpSuccess(false);
      setOtpError(false);
      try {
        const response = await apiCall(
          API_URL + "verify-user-otp",
          {
            user_id: state?.userData?.id,
            token: state?.token,
            verification_type: "withdraw",
            otp: otp,
          },
          {}
        );
        if (response.data.success == "0") {
          localStorage.setItem("userData", JSON.stringify(response.data.data));
          localStorage.setItem("token", token);
          dispatch({
            type: "LOGIN",
            payload: { user: response.data.data, token: token },
          });
          setOtpSuccess(true);
          setOtp(["", "", "", ""]);
          setTimer(30); // Reset the timer to 30 on verification
          handleSubmit();
          setIsOTP(false);
        } else {
          setOtpError(true);
        }
        setShowOtpLoader(false);
      } catch (error) {
        console.error("An error occurred");
            
        } finally {
          
            setIsVerifyingOtp(false); // Reset verifying state
        }
    } else {
      setOtpErrors(valErrors);
        setIsVerifyingOtp(false); // Reset verifying state if there are validation errors
    }
  };

  const validateOtpFields = () => {
    const newErrors = {};
    if (!otp || otp.some((element) => element.trim() == "")) {
      newErrors.otpError = "Otp is required";
    }
    return newErrors;
  };

  const getUserWallet = async () => {
    // Logic to get user wallet group-wise data
    try {
      const response = await apiCall(API_URL +'get-user-wallet', {
          user_id: state.userData.id,
          token: token,
          wallet_id:wallet_id
      });
      if (response.data.success == '0') {
        setWallet(response.data.data);
      }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    if(wallet){
      setUserWalletId(wallet.id);
    }
  }, [wallet]);
 
 
  // const fetchVerification = async () => {
  //   try {
  //     setVerificationLoader(true);
  //     const response = await apiCall(API_URL + 'get-user-verification-level', {
  //       user_id: state?.userData?.id,
  //       token: state?.token,
  //     });
  
  //     if (response.data.success == '0') {
  //       if (response.data.data.caption === 'Level 0') {
  //         setTimeout(() => {
  //           navigate('/verifications',{ state: { showMsg: true } });
  //         }, 2000);
  //       }
  //     }
  //   } catch (error) {
  //     console.error('An error occurred:', error);
  //   } finally {
  //     setVerificationLoader(false);
  //   }
  // };
  const getPaymentMethods = async () => {
    // Logic to get user wallet group-wise data
    try {
      const response = await apiCall(API_URL +'get-payment-methods', {
          user_id: state.userData.id,
          token: token,
          nopaginate:1,
          wallet_type:wallet_type
      });
      if (response.data.success == '0') {
        setPaymentMethods(response.data.data);
      }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
  };

  const handlePaymentMethodIdChange = (payment_method) =>{
    setPaymentMethodId(payment_method);
  }
  
  useEffect(() => {
    setSelectedPaymentMethod(paymentMethods.find(method => method.id === paymentMethodId));
  }, [paymentMethodId]);
  
  useEffect(() => {
    if(selectedPaymentMethod){
      handleStepTabClick('step2')
      setCurrencyId(selectedPaymentMethod?.currency?.id);
      setTransactionType(selectedPaymentMethod?.currency?.type);
      setCurrentCurrencyCode(selectedPaymentMethod?.currency?.code);
      if(selectedPaymentMethod?.currency?.code == 'INR'){
        setCurrentCurrencyRate(1/parseFloat(selectedPaymentMethod?.rate_inr))
      } else {
        setCurrentCurrencyRate(selectedPaymentMethod?.currency?.rate_usd)
      }
      setUsdAmount('')
      setAmount('');
    }
  }, [selectedPaymentMethod]);

  useEffect(() => {
    if(parseFloat(amount) > 0 && amount !== null && amount !== ''){
      if(currentCurrencyRate > 0){
        setUsdAmount(parseFloat(amount / currentCurrencyRate));
      } else {
        setUsdAmount(parseFloat(amount * currentCurrencyRate));
      }
    } else {
      setUsdAmount();
    }
  }, [amount]);

  useEffect(() => {
    if(requestSubmitSuccess){
      setTimeout(function(){
        handleStepTabClick('step1');
      },1000)
    }
  }, [requestSubmitSuccess]);

 const handleAmountChange = (value) => {
  // Ensure value is a valid number
  const usdAmount = parseFloat(value);

  if (!isNaN(usdAmount)) {
    // Convert USD to the selected currency
    console.log('currentCurrencyRate', currentCurrencyRate);
    console.log('usdAmount', usdAmount);
    const convertedRate = (1 / currentCurrencyRate);
    console.log('convertedRate > 0',convertedRate);
    
    const convertedAmount = convertedRate != 0
      ? usdAmount * convertedRate
      : usdAmount; // Handle the case where currentCurrencyRate is 0 or undefined

    setAmount(usdAmount); // Set the amount in USD
    setUsdAmount(convertedAmount); // Set the converted amount in the selected currency
  } else {
    setAmount();
    setUsdAmount();
  }

  setErrors((prev) => ({ ...prev, amount: '' }));
};


  const getAccounts = async() => {
    try {
      var dataPass={};
      if(wallet_id && wallet_id !== null){
        dataPass = {
            user_id: state.userData.id,
            token: token,
            mt5_not_type:'transfer'
        };
      } else {
        dataPass = {
          user_id: state.userData.id,
          token: token,
          mt5_not_type:'both'
      };
      }
      const response = await apiCall(API_URL +'get-user-all-wallets', dataPass);
      if (response.data.success == '0') {
        setMt5Accounts(response.data.data);
        if(wallet_id && wallet_id !== null){
          setToMt5Account(wallet_id);
        }
      }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
  }

  const handleMt5AccountChange = (account) => {
    setToMt5Account(account);
    setErrors((prev) => ({ ...prev, toMt5Account: '' }));
  }

  useEffect(() => {
    if(toMt5Account !== null){
      setSelectedMt5Account(mt5Accounts.find(wallet => wallet.id === toMt5Account));
    }
  }, [toMt5Account]);

  // const handleMt5NumberChange = (value) => {
  //   setMt5Number(value);
  //   setErrors((prev) => ({ ...prev, mt5Number: '' }));
  // };

  const handleBtcwalletAddressChange = (value) => {
    setBtcWalletAddress(value);
    setErrors((prev) => ({ ...prev, btcwalletaddress: '' }));
  };

  const handleUsdtwalletAddressChange = (value) => {
    setUsdtWalletAddress(value);
    setErrors((prev) => ({ ...prev, usdtwalletaddress: '' }));
  };

  const handleAccountNumberChange = (value) => {
    setAccountNumber(value);
    setErrors((prev) => ({ ...prev, account_number: '' }));
  };

  const handleBankNameChange = (value) => {
    setBankName(value);
    setErrors((prev) => ({ ...prev, bank_name: '' }));
  };

  const handleBankAddressChange = (value) => {
    setBankAddress(value);
    setErrors((prev) => ({ ...prev, bank_address: '' }));
  };
  const handleIfscCodeChange = (value) => {
    setIfscCode(value);
    setErrors((prev) => ({ ...prev, ifsc_code: '' }));
  };
  // const handleNameChange = (value) => {
  //   setName(value);
  //   setErrors((prev) => ({ ...prev, name: '' }));
  // };

  const handleAccountHolderNameChange = (value) => {
    setAccountHolderName(value);
    setErrors((prev) => ({ ...prev, account_holder_name: '' }));
  };

  const handleTransactionIdChange = (value) => {
    setTransactionId(value);
    setErrors((prev) => ({ ...prev, transactionId: '' }));
  };

  

  const validateFields = () => {
    const newErrors = {};
    if (!amount) {
      newErrors.amount = 'Amount is required';
    } else if (isNaN(amount) || amount <= 0) {
      newErrors.amount = 'Amount must be a positive number';
    }else if (amount > 0 && parseInt(amount) < 10) {
      newErrors.amount = `Amount must be a 10 or greater`;
    } 
    else if (amount.toString().length > 10) {
      newErrors.amount = 'Amount must be up to 10 digits';
    }
    // if (!transactionId && transactionType === 'crypto')  {
    //   newErrors.transactionId = 'TXID is required';
    // }
    //crypto erros
    if (!toMt5Account) {
      newErrors.toMt5Account = 'MT5 Number is required';
    } 
    
    //else if (isNaN(mt5Number) || mt5Number <= 0 && transactionType === 'crypto') {
    //   newErrors.mt5Number = 'MT5 Number must be a positive number';
    // }
    // if (!name && transactionType !== 'crypto')  {
    //   newErrors.name = 'Name is required';
    // }
    if (!btcWalletAddress && transactionType === 'crypto' && selectedPaymentMethod.currency.code == 'BTC')  {
      newErrors.btcwalletaddress = 'BTC wallet address is required';
    }
    if (!usdtWalletAddress && transactionType === 'crypto' && selectedPaymentMethod.currency.code == 'USDT')  {
      newErrors.usdtwalletaddress = 'USDT wallet address is required';
    }
    if (!accountHolderName && transactionType === 'bank')  {
      newErrors.account_holder_name = 'Account holder name is required';
    }
    if (!accountNumber && transactionType === 'bank')  {
      newErrors.account_number = 'Bank account number is required';
    }
    if (!bankName && transactionType === 'bank')  {
      newErrors.bank_name = 'Bank name is required';
    }
    if (!bankAddress && transactionType === 'bank')  {
      newErrors.bank_address = 'Bank address is required';
    }
    if (!ifscCode && transactionType === 'bank')  {
      newErrors.ifsc_code = 'Ifsc code is required';
    }
    
    
    return newErrors;
  };
  // ---
  const handleSubmit = async() => {
    

    const valErrors = validateFields();
    console.log(valErrors)
    if (Object.keys(valErrors).length === 0) {
      // Create a FormData object
      const formData = new FormData();
      formData.append('user_id',state.userData?.id);
      formData.append('token',state?.token);
      formData.append('type',type);
      formData.append('transaction_type',transactionType);
      formData.append('transfer_type',transferType);
      
      formData.append('payment_method_id',paymentMethodId);
      formData.append('user_wallet_id',toMt5Account);
      formData.append('currency_id',currencyId);
      formData.append('amount',amount);
      formData.append('mt5',toMt5Account);
      formData.append('usdt_wallet_address',usdtWalletAddress);
      formData.append('btc_wallet_address',btcWalletAddress);
      formData.append('account_holder_name',accountHolderName);
      formData.append('account_number',accountNumber);
      formData.append('ifsc_code',ifscCode);
      formData.append('bank_name',bankName);
      formData.append('bank_address',bankAddress);
      // formData.append('name',name);
     

      formData.append('transaction_id',transactionId);
      formData.append('screenshot',screenshot);
      
      // Append each file to FormData
      // selectedDocumentTypes.forEach((value, index) => {
      //   if(selectedFiles[index].length > 0){
      //     formData.append(`document_type_id[]`, value);
      //   }
      // });
      // selectedFiles.forEach((object, index) => {
      //   object.forEach((file, idx) => {
      //     formData.append(`documents[]`, file);
      //   });
      // });

      try {
        // Send FormData using Axios
        setIsLoading(true);
        setRequestSubmitSuccsess(false)
        setRequestSubmitError(false)
        apiCall(API_URL + 'send-withdraw-request', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
            if(response.data.success == '0'){
              setTransactionType('crypto')
              setPaymentMethodId(null)
              setUsdAmount('')
              setAmount('');
              setName(null);
              //setMt5Number(null);
              setBtcWalletAddress(null);
              setUsdtWalletAddress(null);
              setBankAddress(null);
              setBankName(null);
              setIfscCode(null);
              setAccountNumber(null);
              setAccountHolderName(null);
              setTransactionId('');
              setScreenshot(null);
              setRequestSubmitSuccsess(true)
              
            } else {
              setRequestSubmitError(true)
              setRequestSubmitErrorMsg(response.data.data.error)
            }
            setIsLoading(false);
        })
        .catch(error => {
          setIsLoading(false);
          setRequestSubmitError(true)
          setRequestSubmitErrorMsg(error.response.data.message)
          //console.error('Error uploading profile picture:', error);
        });
      } catch (error) {
        //console.error('Error uploading files:', error);
      }
    } else {
      // Set errors to state
      setErrors(valErrors);
    }
  };

  // --- Payment section Tabbing Js Start
  const [stepTab, setStepTab] = useState('step1');

  const handleStepTabClick = (stepTabId) => {
    if(stepTabId == 'step1'){
      setPaymentMethodId(null);
    }
    setStepTab(stepTabId);
    document.getElementsByClassName('account-content-area')[0].scrollTo(0, 0);
  };
  // --- Payment section Tabbing Js End


  
  // --- 
  const demoonChange = () => {

  };
  // --- 
  const fetchBanner = async () => {
    try {
        const response = await apiCall(`${API_URL}get-withdraw-banner`, {});
        


        if (response.data.success) {
            // setBannerImg(response.data.data.image_url);
            setBannerImg({
              imageUrl: response.data.data.image_url,
              link: response.data.data.link
            });
        } else {
            console.error("API Response error:", response.data.message || 'Unknown error');
        }
    } catch (error) {
        console.error("Error fetching banner:", error.message || error);
    } finally {
        setLoading(false);
    }
    };

  return (
    <>

    {stepTab === 'step1' ? ( 
    <Breadcrumb breadcrumbIcon="WithdrawSvg" breadcrumbHeading="Withdraw" />
    ) : stepTab === 'step2' ? (
      <Breadcrumb onClick={() => handleStepTabClick('step1')} backBtn="bmb-back-btn" breadcrumbIcon="WithdrawSvg" breadcrumbHeading="Withdraw" />
    ) : null }
    
    <div className="container-lg pt-3 pb-5 px-lg-4">
      
      <div className="row row-gap-4">

        <div className="col-12">
          {/* <BannerImg src={bannerImg} link="/"/> */}
          { loading ? (
              <div className="skeletant-bx skeletant-design sh-95"></div>
            ) : (
              bannerImg ? (
                <BannerImg src={bannerImg.imageUrl} link={bannerImg.link} />
              ) : ''
          ) }
        </div>

        {/* --- Payment Methods Section Step 1 Start --- */}
        <div className={`col-lg-12 ${stepTab === 'step1' ? '' : 'd-none'} `} id="step1">
         
            <div className="common-box cb-p-bg-none">
              <div className="common-box-heading">All payment methods</div>
              <div className="row row-gap-4">

                {paymentMethods.length == 0 && 
                    <>
                    {/* --- Skeletant Bx Start --- */}
                    <div className="col-sm-6">
                        <div className="skeletant-bx skeletant-design sh-171">
                        </div>
                    </div>
    
                    <div className="col-sm-6">
                        <div className="skeletant-bx skeletant-design sh-171">
                        </div>
                    </div>
    
                    <div className="col-sm-6">
                        <div className="skeletant-bx skeletant-design sh-171">
                        </div>
                    </div>
    
                    <div className="col-sm-6">
                        <div className="skeletant-bx skeletant-design sh-171">
                        </div>
                    </div>
    
                    <div className="col-sm-6">
                        <div className="skeletant-bx skeletant-design sh-171">
                        </div>
                    </div>
    
                    <div className="col-sm-6">
                        <div className="skeletant-bx skeletant-design sh-171">
                        </div>
                    </div>
                    {/* --- Skeletant Bx End --- */}
                    </>
                }
                
                {paymentMethods.map((item, index) => (
                  <PaymentMethodCard
                    key={index}
                    imgSrc={item.image_url}
                    label={item.name}
                    isUnavailable={item.status === 'active' ? false : true}
                    processingTime={item.name == 'Bank Transfer' ? `${item.processing_time}`:`Instant - ${item.processing_time}`}
                    fee={`${item.fee} %`}
                    limits={`Minimum Withdraw: $10`}
                    //onClick={() => handleStepTabClick('step2')}
                    onClick={() =>handlePaymentMethodIdChange(item.id)}
                  />
                ))}

                {/*  <PaymentMethodCard
                  imgSrc={USDTERC20}
                  label="USDT ERC20"
                  isUnavailable={true}
                  processingTime="Instant - 30 minutes"
                  fee="0%"
                  limits="10 - 1,000 USD"
                  onClick={() => handleStepTabClick('step2')}
                /> */}
              </div>
            </div>          
        </div>
        {/* --- Payment Methods Section Step 1 End --- */}


        {/* --- Payment Form Section Step 2 Start --- */}
            <div className={`col-xl-8 col-12 mx-auto ${stepTab === 'step2' ? '' : 'd-none'} `} id="step2" onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault(); // Prevent default form submission
          handalSendOtp(); // Call your submit handler
        }
      }}
      tabIndex={0} >

          <div className="common-box">

            <div className="common-box-heading">Fill your withdraw details</div>
            {/* <div className="common-box-sub-heading mb-4">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div> */}
            
            <div className="payment-data-bx pdb-style-2 pdb-padding mb-3">
              <div className="pdb-item minimum-pdb-item">
                  <div className="pdb-i-lable">Minimum withdraw:</div>
                  <div className="pdb-i-data">10<span className="pdb-currency">USD</span></div>
                </div>
            </div>

            <div className="row signinup-group-style sg-fem-style">

            <div className="col-sm-6">
                <AccountDropItem
                    className={errors.toMt5Account ? "error-group" : ""}
                    label="MT5 Accounts"
                    img={mt5}
                    heading={`${selectedMt5Account ? selectedMt5Account.account == 'mt5' ? selectedMt5Account.account_number+" ( "+selectedMt5Account.balance+" USD )": 'IB Wallet'+" ( "+(selectedMt5Account.program ? selectedMt5Account.program.name : 'auto')+" )"+" ( "+selectedMt5Account.balance+" USD )" : "Select Account" }`}
                    options={mt5Accounts}
                    onChange={handleMt5AccountChange}
                    pageAccessName='withdraw'
                    selected={selectedMt5Account}
                />
                {errors.toMt5Account && <span className="form-error-msg">{errors.toMt5Account}</span>}
              </div>   

              <div className="col-sm-6">
                <Input
                  className={`sgl-icon ${errors.amount ? "error-group" : ""}`}
                  heading="Amount (USD)"
                  sgl="USD"
                  type="number"
                  placeholder="Enter Amount in USD"
                  value={amount}
                  onChange={handleAmountChange} // Pass the function reference
                />
                {errors.amount && <span className="form-error-msg">{errors.amount}</span>}
            </div>
            
            <div className="col-sm-6">
              <div className="conversion-rate-bx crb-style-2">
                <div className="current-rate">1 {currentCurrencyCode} = {currentCurrencyRate} USD</div>
                <div className="current-amount">
                  {currentCurrencyCode}<span>{usdAmount}</span>
                </div>
              </div>
            </div>  
              {/* <div className={`col-sm-6 ${transactionType !== 'bank' ? 'd-none':''}`}>
                <Input heading="Name" type="text" placeholder="Enter Name" onChange={handleNameChange} />
                {errors.name && <span className="form-error-msg">{errors.name}</span>}
              </div> */}
              {/* <div className="col-sm-6">
                <Input heading="MT5 Number" type="number" placeholder="Enter MT5 Number" onChange={handleMt5NumberChange} />
                {errors.mt5Number && <span className="form-error-msg">{errors.mt5Number}</span>}
              </div> */}
              <div className={`col-sm-6 ${transactionType == 'crypto' && selectedPaymentMethod?.currency?.code == 'BTC' ? '':'d-none'}`}>
                <Input className={errors.btcwalletaddress ? "error-group" : ""} heading="BTC Wallet Address" type="text" placeholder="Enter BTC Wallet Address" onChange={handleBtcwalletAddressChange} />
                {errors.btcwalletaddress && <span className="form-error-msg">{errors.btcwalletaddress}</span>}
              </div>

              <div className={`col-sm-6 ${transactionType == 'crypto' && selectedPaymentMethod?.currency?.code == 'USDT' ? '':'d-none'}`}>
                <Input className={errors.usdtwalletaddress ? "error-group" : ""} heading="USDT Wallet Address" type="text" placeholder="Enter USDT Wallet Address" onChange={handleUsdtwalletAddressChange} />
                {errors.usdtwalletaddress && <span className="form-error-msg">{errors.usdtwalletaddress}</span>}
              </div>

              <div className="col-sm-6 d-none">
                <Input heading="TXID" type="text" placeholder="Enter TXID" onChange={handleTransactionIdChange} />
              </div>

              <div className={`col-sm-6 ${transactionType !== 'bank' ? 'd-none':''}`}>
                <Input className={errors.account_holder_name ? "error-group" : ""} heading="Account Holder Name" type="text" placeholder="Enter Account Holder Name" onChange={handleAccountHolderNameChange} />
                {errors.account_holder_name && <span className="form-error-msg">{errors.account_holder_name}</span>}
              </div>

              <div className={`col-sm-6 ${transactionType !== 'bank' ? 'd-none':''}`}>
                <Input className={errors.account_number ? "error-group" : ""} heading="Bank Account Number" type="text" placeholder="Enter Bank Account Number" onChange={handleAccountNumberChange} />
                {errors.account_number && <span className="form-error-msg">{errors.account_number}</span>}
              </div>

              <div className={`col-sm-6 ${transactionType !== 'bank' ? 'd-none':''}`}>
                <Input className={errors.ifsc_code ? "error-group" : ""} heading="IFSC Code" type="text" placeholder="Enter IFSC Code" onChange={handleIfscCodeChange} />
                {errors.ifsc_code && <span className="form-error-msg">{errors.ifsc_code}</span>}
              </div>

              <div className={`col-sm-6 ${transactionType !== 'bank' ? 'd-none':''}`}>
                <Input className={errors.bank_name ? "error-group" : ""} heading="Bank Name" type="text" placeholder="Enter Bank Name" onChange={handleBankNameChange} />
                {errors.bank_name && <span className="form-error-msg">{errors.bank_name}</span>}
              </div>

              <div className={`col-sm-6 ${transactionType !== 'bank' ? 'd-none':''}`}>
                <Input className={errors.bank_address ? "error-group" : ""} heading="Bank Address" type="text" placeholder="Enter Bank Address" onChange={handleBankAddressChange} />
                {errors.bank_address && <span className="form-error-msg">{errors.bank_address}</span>}
              </div>

              {/* <div className="col-sm-6">
                <Input heading="MT5 Account" type="text" placeholder="Enter MT5 Account" onChange={handleMt5AccountNumberChange} />
              </div> */}
             
            </div>

          </div>

          <div className="mt-4">
          {!isLoading && <div className="common-submit-btn" onClick={handalSendOtp}>Withdraw</div>
          }

          {isLoading && <div className="lsb-loader-btn">
              <img src={Img.loading} alt="" />Please wait ....
            </div>
          }
           {/* {msg && <FlashMessage type="success" isvisible={true} message="Please Upgrade your level" />} */}
           {requestSubmitSuccess && <FlashMessage type="success" isvisible={true} message="Withdraw request submitted successfully" />}
            {requestSubmitError && <FlashMessage type="error" isvisible={true} message={requestSubmitErrorMsg} />}
         </div>

        </div>
        {/* --- Payment Form Section Step 2 End --- */}

      </div>

    </div>
     {/* --- OTP Modal --- */}
     <Modal className="zoom custom-content" centered show={isOTP} onHide={handleOTPClose}>
      <div className="custom-modal-header">
          <div className="cmh-lable">Confirmation</div>
          <div className="cmh-sub-lable">Please, enter a verification code sent to your email</div>
 
          <span className="close-icon" onClick={handleOTPClose}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368"></path>
              </svg>
          </span>
      </div>

      <div
        className={`custom-modal-data px-3 pb-3 pt-2`}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault(); // Prevent default behavior
            handalVerifyOtp(); // Call your verify function
          }
        }}
        tabIndex={0} // Make the div focusable
      >
          <div className="row signinup-group-style">

            <div className="col-12">
                <div className={`signinup-group otp-group`}>
                    {otp.map((digit, index) => (
                        <>
                          <input key={index}  inputMode="numeric" type="text" maxLength="1" placeholder=" " value={digit} onChange={(e) => handleChange(e, index)} onKeyDown={(e) => handleKeyDown(e, index)} ref={inputRefs[index]} />
                        </>
                    ))}
                      
                </div>
                {otpErrors.otpError && <span className="form-error-msg text-center">{otpErrors.otpError}</span>}
            </div>

            <div className="col-12">
                <div className="otp-resend-bx">
                    <div>Have Not Received Code</div>
                    {/* <div><div className="reotp-btn">Resend</div></div> */}
                    {(!showOtpLoader && !showLoader) && (
                    timer > 0 ? (
                      <div>
                        Resend In <span>{timer}s</span>
                      </div>
                    ) : (
                      <div>
                        <div className="reotp-btn" onClick={handalReSendOtp}>
                          Resend
                        </div>
                      </div>
                    ))}
                </div>
            </div>

            <div className="col-12 mt-2">
            {!showOtpLoader && <div className="common-submit-btn" onClick={() => handalVerifyOtp()}>Continue</div>
            }
            {showOtpLoader && (
    <div className="lsb-loader-btn">
        <img src={Img.loading} alt="" />Please wait...
    </div>
)}

            </div>

          </div>
      </div>
    </Modal>
      {otpSuccsess && (
        <FlashMessage
          type="success"
          isvisible={true}
          message="Otp sent successfully."
        />
      )}
      {otpError && (
        <FlashMessage
          type="error"
          isvisible={true}
          message="Invalid otp or expired"
        />
      )}
    </>
  )
}
