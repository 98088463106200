import React from 'react'
import { Link } from 'react-router-dom'

export default function BannerImg({ src, link }) {
  return (
    <Link to={link} className="banner-img-bx">
        <img src={src} alt=""/>
    </Link>
  )
}
