import React, { useState, useEffect, useRef } from 'react';
import { useAuth, getLoggedInUserData, getToken } from '../../context/AuthContext';
import useApi from '../../utility/apiCall';
import API_URL from '../../config/config';

import Input from '../../components/CommonInput/Input';

// Imges & Icons Files
// import * as Img from '../../components/Img'
import Icons from '../../components/icons';
import foundClient from '../../img/icons/not-found-client.png';

// Design Files
// import CommonLayout from '../PageCommonLayout/CommonLayout';
import Breadcrumb from '../../components/Breadcrumb';
import EmptyBox from '../../components/EmptyBox'; 
import { useParams } from 'react-router-dom';
import empty2 from '../../img/icons/empty2.png';
export default function PageIBMyClient() {
    const { apiCall } = useApi();
    const { state, dispatch } = useAuth();
    const token = state.token || '';
    const isInitialMount = useRef(true);
    const { id } = useParams();
    const [isLoading, setIsLoading ] = useState(null);
    const [isClientLoading, setIsClientLoading ] = useState(null);
    const [page,setPage] = useState(1);
    const [goPage, setGoPage ] = useState(null);
    const [totalPages,setTotalPages] = useState(0);
    const [sortBy, setSortBy] = useState('id');
    const [sortOrder, setSortOrder] = useState('asc');
    const [sortChange, setSortChange] = useState(null);
    const [keyword, setKeyword] = useState('');
    const [reset, setReset] =useState(false);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [clientTrades, setClientTrades] = useState(null);
    const [currentTab, setCurrentTab] = useState('rewards');
    const [clientData, setClientData] = useState();
    const [clientAccount, setClientAccount] = useState();
    const [title, setTitle] = useState("Rewards");
    const [clients, setClients] = useState();

    const DemoOnChange = (page) => {
        if(parseInt(page) === 0){
            page = 0;
        }
        if(page < parseInt(totalPages)){
            setGoPage(page);
            setPage(page);
        } else {
            setPage(totalPages);
            setGoPage(totalPages);
        }
    }
    const changePrevPage = () => {
        if(page > 1){
          setPage(page-1);
        } 
    }
    const changeNextPage = () => {
        if(page < totalPages){
            setPage(page+1);
        }
    }
    const setSortByOrder = (sortByField) =>{
        let newSortOrder = sortOrder == 'asc' ? 'desc' : 'asc';
        setSortBy(sortByField);
        setSortOrder(newSortOrder);
        setSortChange(sortBy+','+sortOrder);
    }
    useEffect(() => {
        const fetchData = async () => {
          const loggedInUser = getLoggedInUserData();
          const isUserLoggedIn = !!loggedInUser;
          
          if (isUserLoggedIn) {
            if (loggedInUser.id && token) {
              try {
                getClientDetails();
                getClientTrades();
              } catch (error) {
                console.error('Failed to fetch ib data:', error);
              }
            }
          }
        };
    
        if (isInitialMount.current) {
          isInitialMount.current = false;
          fetchData();
        }
    }, [token]);
    useEffect(() => {
        if(currentTab == "rewards")
        {
            getClientTrades();
        }
        if(currentTab == "accounts")
        {
            getClientAccounts();
        }
        if(currentTab == "client_list")
        {
            getClients();
        }
      }, [page]);
    const handleTabChange = async(tab) => {
        setCurrentTab(tab);
        if(tab == 'rewards' && currentTab != "rewards"){
            setPage(1);
            setTotalPages(1);
            setGoPage(null);
            setSortBy('id');
            setSortOrder('asc');
            setTitle("Rewards");
            getClientTrades();
        } else if(tab == 'accounts' && currentTab != "accounts"){
            setTitle("Acconts");
            setPage(1);
            setTotalPages(1);
            setGoPage(null);
            setSortBy('id');
            setSortOrder('asc');
            getClientAccounts();
        } else if(tab == 'client_list' && currentTab != "client_list"){
            setTitle("Client List");
            setTotalPages(1);
            setGoPage(null);
            setSortBy('id');
            setSortOrder('asc');
            getClients();
        }
    }
    useEffect(() => {        
        if(sortChange !== null){
            if(currentTab == 'rewards'){
                getClientTrades();
            } else if(currentTab == 'accounts'){
                getClientAccounts();
            } else if(currentTab == 'client_list'){
                getClients();
            }
        }
    }, [sortChange]);
   const getClientTrades = async () => {
        try {
            setIsLoading(true);
            const response = await apiCall(API_URL +'get-client-trade', {
                user_id: state.userData.id,
                token: token,
                page:page,
                per_page:10,
                sort_by:sortBy,
                sort_order:sortOrder,
                client_id:id
            });
            if (response.data.success == '0') {
                setClientTrades(response.data.data.data);
                setTotalPages(response.data.data.last_page)
            }
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const getClientDetails = async () => {
        try {
            setIsClientLoading(true);
            const response = await apiCall(API_URL +'get-client-details', {
                user_id: state.userData.id,
                token: token,
                page:page,
                per_page:10,
                sort_by:sortBy,
                sort_order:sortOrder,
                client_id:id
            });
            if (response.data.success == '0') {
                setClientData(response.data.data);
            }
            setIsClientLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const getClientAccounts = async () => {
        try {
            setIsLoading(true);
            const response = await apiCall(API_URL +'get-client-account', {
                user_id: state.userData.id,
                token: token,
                page:page,
                per_page:10,
                sort_by:sortBy,
                sort_order:sortOrder,
                client_id:id
            });
            if (response.data.success == '0') {
                setClientAccount(response.data.data.data);
                setTotalPages(response.data.data.last_page)
            }
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const getClients = async () => {
        try {
            setIsLoading(true);
            const response = await apiCall(API_URL +'get-clients', {
                user_id: state.userData.id,
                token: token,
                page:page,
                per_page:10,
                sort_by:sortBy,
                sort_order:sortOrder,
                client_id:id
            });
            if (response.data.success == '0') {
                setClients(response.data.data.data);
                setTotalPages(response.data.data.last_page)
            }
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const getSymbolImage = (symbolImages) => {
        // if (symbolImages.length == 2) {
        //     try {
        //       return (
        //         <div className="symbol-icons">
        //           <img src={symbolImages[0]} alt="" />
        //           <img src={symbolImages[1]} alt="" />
        //         </div>
        //       );
            
        //     } catch (error) {
        //       return ''; // Return an empty string if the image is not found
        //     }
          
        // } else if(symbolImages.length == 1){
        //   try {
        //     return (
        //       <div className="symbol-icons si-single">
        //         <img src={symbolImages[0]} alt="" />
        //       </div>
        //     );
        //     } catch (error) {
        //     return ''; // Return an empty string if the image is not found
        //     }
        // } else {
          return '';
        // }
      };
  return (
    <>
        <Breadcrumb backBtn="bmb-back-btn" backBtnLink="/ib-reports" breadcrumbIcon="UserSvg" breadcrumbHeading="Client Details" middleLink="/ib-reports" middleHeading="Reports"  />

        <div className="container-lg pt-3 pb-5 px-lg-4">
            <div className="row row-gap-4">

                {/*  */}
                <div className="col-xl-10 mx-auto">
                    <div className="common-box">

                        {clientData && !isClientLoading &&(
                            <div className="myclient-info-box">
                                <div className="myclient-id">{clientData && (clientData.first_name + " " +clientData.last_name)}</div>
                                <div className="myclient-tag">
                                {clientData.verified_caption && ( <div className="myclient-tag-item myclient-current">{clientData.verified_caption}</div>)}
                                <div className="myclient-tag-item">{clientData && (clientData.country_name)}</div>
                                </div>
                            </div>
                        )}
                        {isClientLoading &&
                           <div className="myclient-info-box">
                                <div style={{width: "100%"}} className="skeletant-bx skeletant-design sh-47"></div>
                            </div>
                        }
                        <div className="common-tabs-bx ctb-style-2">
                            <div className={`ctb-item ${currentTab == 'rewards'?'active':''}`} onClick={()=>handleTabChange('rewards')}>Rewards</div>
                            <div className={`ctb-item ${currentTab == 'accounts'?'active':''}`} onClick={()=>handleTabChange('accounts')}>Accounts</div>
                            <div className={`ctb-item ${currentTab == 'client_list'?'active':''}`} onClick={()=>handleTabChange('client_list')}>Client List</div>
                        </div>

                    </div>
                </div>
                {/*  */}

                {/*  */}
                <div className="col-xl-10 mx-auto">
                    <div className="common-box">
                        <div className="common-box-heading">{title}</div>
                        <div className="table-responsive">
                        {currentTab == 'rewards' && clientTrades && clientTrades.length > 0 && !isLoading && (
                            <> 
                            <table className="custom-table">
                                <thead>
                                    <tr>
                                        <th className={sortBy === 'user_id' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('user_id')}>Client Id<div className="th-sort-icons" ><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                        <th className={sortBy === 'account' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('account')}>Platform<div className="th-sort-icons" ><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                        <th className={sortBy === 'account_number' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('account_number')}>Account Id<div className="th-sort-icons" ><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                        <th className={sortBy === 'symbol_name' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('symbol_name')}>Symbol<div className="th-sort-icons" ><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                        <th className={sortBy === 'trade_id' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('trade_id')}>Trade ID <div className="th-sort-icons" ><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                        <th className={sortBy === 'side' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('side')}>Side <div className="th-sort-icons"><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                        <th className={sortBy === 'position' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('position')}>Position<div className="th-sort-icons" ><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                        <th className={sortBy === 'lots' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('lots')}>Volume <div className="th-sort-icons"><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                        <th className={sortBy === 'trade_execution_date' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('trade_execution_date')}>Trade Execution Time<div className="th-sort-icons"><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div> </th>
                                        <th className={sortBy === 'price' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('price')}>Price<div className="th-sort-icons"><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                        <th className={sortBy === 'profit' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('profit')}>Profit<div className="th-sort-icons"><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {clientTrades.map(trade => (
                                    
                                    <tr key={trade.id}>
                                        <td>{trade.user ? trade.user.sequence : "-"}</td> 
                                        <td>{trade.account ? trade.account : "-"}</td>
                                            <td>{trade.account_number ? trade.account_number : "-"}</td>  
                                            <td><div className="symbol-bx">
                                                {getSymbolImage(trade.symbol.symbol_images)}
                                                {trade.symbol_name ? trade.symbol_name : "-"}
                                                </div></td> 
                                        <td>{trade.trade_id}</td>
                                        <td className={`${
                                                trade.side === "buy" ? "td-green" : "td-red"
                                                }`}>{trade.side ? trade.side : '-'}
                                            </td>
                                        <td>{trade.position ? trade.position : '-'}</td>
                                        <td>{trade.lots}</td>
                                        <td>{trade.open_date ?trade.open_date_formattad : "-"}</td>
                                            <td>${trade.price ? trade.price.toFixed(5) : 0}</td>
                                            <td className={`${ (trade.position != 'close' && trade.loss == undefined) ? '' : trade.profit > 0 ? "td-green" : "td-red"}`}>
                                                {(trade.position != 'close' && trade.loss == undefined) ? '-'
                                                : trade.profit > 0
                                                ? trade.profit.toFixed(4)
                                                : "- " + trade.loss.toFixed(4)}
                                            </td>
                                        
                                       
                                    </tr>
                                    ))}
                                </tbody>
                            </table>  
                            </>      
                        )}
                         {currentTab == 'accounts' && clientAccount && clientAccount.length > 0 && !isLoading && (
                            <> 
                            <table className="custom-table">
                                <thead>
                                    <tr>
                                        <th className={sortBy === 'account_number' ? 'active ' + sortOrder : ''} onClick={() => setSortByOrder('account_number')}>Account Number<div className="th-sort-icons"><Icons.CaretUpSvg /><Icons.CaretDownSvg /></div></th>
                                        <th className={sortBy === 'deposit' ? 'active ' + sortOrder : ''} onClick={() => setSortByOrder('deposit')}>Deposits<div className="th-sort-icons"><Icons.CaretUpSvg /><Icons.CaretDownSvg /></div></th>
                                        <th className={sortBy === 'withdraw' ? 'active ' + sortOrder : ''} onClick={() => setSortByOrder('withdraw')}>Withdrawals<div className="th-sort-icons"><Icons.CaretUpSvg /><Icons.CaretDownSvg /></div></th>
                                        <th className={sortBy === 'balance' ? 'active ' + sortOrder : ''} onClick={() => setSortByOrder('balance')}>Balance<div className="th-sort-icons"><Icons.CaretUpSvg /><Icons.CaretDownSvg /></div></th>
                                        <th className={sortBy === 'equity' ? 'active ' + sortOrder : ''} onClick={() => setSortByOrder('equity')}>Equity<div className="th-sort-icons"><Icons.CaretUpSvg /><Icons.CaretDownSvg /></div></th>
                                        <th className={sortBy === 'profit' ? 'active ' + sortOrder : ''} onClick={() => setSortByOrder('profit')}>Profit<div className="th-sort-icons"><Icons.CaretUpSvg /><Icons.CaretDownSvg /></div></th>
                                        <th className={sortBy === 'trades' ? 'active ' + sortOrder : ''} onClick={() => setSortByOrder('trades')}>Trades<div className="th-sort-icons"><Icons.CaretUpSvg /><Icons.CaretDownSvg /></div></th>
                                        <th className={sortBy === 'lots' ? 'active ' + sortOrder : ''} onClick={() => setSortByOrder('lots')}>Vol.lots<div className="th-sort-icons"><Icons.CaretUpSvg /><Icons.CaretDownSvg /></div></th>
                                        <th className={sortBy === 'created_at' ? 'active ' + sortOrder : ''} onClick={() => setSortByOrder('created_at')}>Created<div className="th-sort-icons"><Icons.CaretUpSvg /><Icons.CaretDownSvg /></div></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {clientAccount.map(account => (  
                                    <tr key={account.id}>
                                        <td>{account.account_number}</td>
                                        <td>{account.deposit ? account.deposit.toFixed(4) : 0}</td>
                                        <td>{account.withdraw ? account.withdraw.toFixed(4) : 0}</td>
                                        <td>{account.balance}</td>
                                        <td>{account.equity}</td>
                                        <td>{account.profit}</td>
                                        <td>{account.trade}</td>
                                        <td>{account.lots ? account.lots.toFixed(2) : "0"}</td>
                                        <td>{account.created_at_formattad}</td>
                                    </tr>
                                    ))}
                                </tbody>
                            </table>  
                            </>      
                            )}
                            {currentTab == 'client_list' && clients && clients.length > 0 && !isLoading && (
                            <> 
                            <table className="custom-table">
                                <thead>
                                    <tr>
                                        <th className={sortBy === 'first_name' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('first_name')}>Name <div className="th-sort-icons" ><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                        <th className={sortBy === 'country' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('country')}>Country <div className="th-sort-icons"><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                        <th className={sortBy === 'city' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('city')}>City <div className="th-sort-icons"><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                        <th className={sortBy === 'email' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('email')}>Email <div className="th-sort-icons"><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                        <th className={sortBy === 'verification_level_id' ? 'active '+sortOrder : ''} onClick={()=>setSortByOrder('verification_level_id')}>Verification Level <div className="th-sort-icons"><Icons.CaretUpSvg/><Icons.CaretDownSvg/></div></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {clients.map(client => (
                                    
                                    <tr key={client.id}>
                                        <td>{client.name}</td>
                                        <td>{client.country_name }</td>
                                        <td>{client.city && (client.city)}</td>
                                        <td>{client.email}</td>
                                        <td>{!client.verified ? "Level 0" : "Level 1"}</td>
                                    </tr>
                                    ))}
                                </tbody>
                            </table>  
                            </>      
                        )}
                            {currentTab === 'rewards' && clientTrades && clientTrades.length == 0 && !isLoading && (
                                <EmptyBox
                                className="empty-div-bx no-invert"
                                img={empty2}
                                eh="Trades not found"
                              />
                            )}
                            {currentTab == 'accounts' && clientAccount && clientAccount.length == 0 && !isLoading && (
                                <EmptyBox
                                className="empty-div-bx no-invert"
                                img={foundClient}
                                eh="Accounts not found"
                                esh="Try adjusting filters to get results."
                                />
                            )}
                            {currentTab === 'client_list' && clients && clients.length == 0 && !isLoading && (
                                <EmptyBox
                                className="empty-div-bx no-invert"
                                img={foundClient}
                                eh="Clients not found"
                                esh="Try adjusting filters to get results."
                                />
                            )}
                            {isLoading &&
                                <div className="row mx-0 row-gap-2">
                                    <div className="col-12 px-0">
                                        <div className="skeletant-bx skeletant-design sh-47"></div>
                                    </div>
                                    <div className="col-12 px-0">
                                        <div className="skeletant-bx skeletant-design sh-47"></div>
                                    </div>
                            
                                    <div className="col-12 px-0">
                                        <div className="skeletant-bx skeletant-design sh-47"></div>
                                    </div>
                            
                                    <div className="col-12 px-0">
                                        <div className="skeletant-bx skeletant-design sh-47"></div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="filter-bx ib-bottom-filter mt-4">

                            <div className="filter-left d-flex justify-content-end align-items-center column-gap-2">
                                <div className="signinup-group-style d-flex column-gap-2 align-items-center justify-content-center">
                                    <div className="sgr-outer-label mb-0">Go to</div>
                                    <Input className="gopageinput mb-0" type="number" placeholder="Page"value={goPage ? goPage : ''} onChange={DemoOnChange} />
                                </div>
                            </div>
                            <div className="filter-right">
                                <div className="pagination-bx">
                                    <div className="pagination-btn pb-left" onClick={changePrevPage}>Prev</div>
                                    <div className="pagination-text"><div>{page}</div><span>/</span><div>{totalPages}</div></div>
                                    <div className="pagination-btn pb-right" onClick={changeNextPage}>Next</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*  */}

            </div>
        </div>
      
    </>
  )
}
